export const AUSTIN_TENANTS = {
    AWS: 'AWS',
    WHS: 'WHS'
};
/**
 * These maps were copied from AustinStaticWebsite/src/common/modules/environment.js
 * @todo this is a temp implementation for getting region;  This should be removed in favor of Region specifi App config or feature toggles
 */

/**
 * This map defines all top-level URLs for hosted static websites. It's primarily used to detect the client environment
 * so that the rest of the mappings (Cognito, AppSync endpoint, Micro-Frontend endpoint, etc) can be established.
 *
 */
export const WHS_ENVIRONMENTS = {
    ALPHA: 'alpha.austin.a2z.com',
    BETA_EU: 'eu-beta.austin.a2z.com',
    BETA_FE: 'fe-beta.austin.a2z.com',
    BETA_NA: 'na-beta.austin.a2z.com',
    GAMMA_EU: 'eu-gamma.austin.a2z.com',
    GAMMA_FE: 'fe-gamma.austin.a2z.com',
    GAMMA_NA: 'na-gamma.austin.a2z.com',
    PROD_EU: 'eu.ehs-amazon.com',
    PROD_FE: 'fe.ehs-amazon.com',
    PROD_NA: 'na.ehs-amazon.com'
};

export const AWS_ENVIRONMENTS = {
    GAMMA_EU_AWS: 'emea-gamma-aws.austin.a2z.com',
    GAMMA_FE_AWS: 'apac-gamma-aws.austin.a2z.com',
    GAMMA_NA_AWS: 'amer-gamma-aws.austin.a2z.com',
    PROD_EU_AWS: 'emea-aws.ehs-amazon.com',
    PROD_FE_AWS: 'apac-aws.ehs-amazon.com',
    PROD_NA_AWS: 'amer-aws.ehs-amazon.com'
};
export const ENVIRONMENT = {
    LOCAL: 'localhost',
    ...AWS_ENVIRONMENTS,
    ...WHS_ENVIRONMENTS
};

export const REGIONS = {
    NA: 'NA',
    EU: 'EU',
    FE: 'FE'
};

export const STAGES = {
    LOCAL: 'LOCAL',
    ALPHA: 'ALPHA',
    BETA: 'BETA',
    GAMMA: 'GAMMA',
    GAMMA_AWS: 'GAMMA_AWS',
    PROD: 'PROD',
    PROD_AWS: 'PROD_AWS',
};

const { NA, EU, FE } = REGIONS;
const { LOCAL, ALPHA, BETA, GAMMA, GAMMA_AWS, PROD, PROD_AWS } = STAGES;

export const DOMAIN_MAPPINGS = [
    // Dev
    { region: NA, domain: ENVIRONMENT.LOCAL, stage: LOCAL },
    // Alpha
    { region: NA, domain: ENVIRONMENT.ALPHA, stage: ALPHA },
    // Beta
    { region: EU, domain: ENVIRONMENT.BETA_EU, stage: BETA },
    { region: FE, domain: ENVIRONMENT.BETA_FE, stage: BETA },
    { region: NA, domain: ENVIRONMENT.BETA_NA, stage: BETA },
    // Gamma
    { region: EU, domain: ENVIRONMENT.GAMMA_EU, stage: GAMMA },
    { region: FE, domain: ENVIRONMENT.GAMMA_FE, stage: GAMMA },
    { region: NA, domain: ENVIRONMENT.GAMMA_NA, stage: GAMMA },
    // AWS Gamma
    { region: EU, domain: ENVIRONMENT.GAMMA_EU_AWS, stage: GAMMA_AWS },
    { region: FE, domain: ENVIRONMENT.GAMMA_FE_AWS, stage: GAMMA_AWS },
    { region: NA, domain: ENVIRONMENT.GAMMA_NA_AWS, stage: GAMMA_AWS },

    // Prod
    { region: EU, domain: ENVIRONMENT.PROD_EU, stage: PROD },
    { region: FE, domain: ENVIRONMENT.PROD_FE, stage: PROD },
    { region: NA, domain: ENVIRONMENT.PROD_NA, stage: PROD },

    // AWS Prod
    { region: EU, domain: ENVIRONMENT.PROD_EU_AWS, stage: PROD_AWS },
    { region: FE, domain: ENVIRONMENT.PROD_FE_AWS, stage: PROD_AWS },
    { region: NA, domain: ENVIRONMENT.PROD_NA_AWS, stage: PROD_AWS }
];

export function getAustinTenant() {
    return isAws() ? AUSTIN_TENANTS.AWS : AUSTIN_TENANTS.WHS;
}

export function getAppRegionFromHostname(hostname = window.location.hostname) {
    return DOMAIN_MAPPINGS.find(({ domain }) => domain.includes(hostname));
}

export function getStageFromHostname(hostname = window.location.hostname) {
    return DOMAIN_MAPPINGS.find(({ domain }) => domain === hostname)?.stage || '';
}

/**
 * Determines if current environment is AWS (gamme or prod).
 *
 * @return {Boolean}
 */
export function isAws() {
    const {
        GAMMA_EU_AWS,
        GAMMA_FE_AWS,
        GAMMA_NA_AWS,
        PROD_EU_AWS,
        PROD_FE_AWS,
        PROD_NA_AWS
    } = ENVIRONMENT;

    return [
        GAMMA_EU_AWS,
        GAMMA_FE_AWS,
        GAMMA_NA_AWS,
        PROD_EU_AWS,
        PROD_FE_AWS,
        PROD_NA_AWS
    ].includes(window.location.hostname);
}
