/* istanbul ignore file */
import {
    awsCreateIncidentQuestions,
    createIncidentQuestions,
    createIncidentWithPapiQuestions
} from '../../questions/createIncident';
import {
    createNonAmazonian,
    createOTRNonAmazonian
} from '../../questions/createNonAmazonian';

export const FORM_TYPES = {
    CREATE_INCIDENT: 'CREATE_INCIDENT',
    CREATE_INCIDENT_AWS: 'CREATE_INCIDENT_AWS',
    CREATE_INCIDENT_PAPI: 'CREATE_INCIDENT_PAPI',
    CREATE_CONTRACTOR: 'CREATE_CONTRACTOR',
    CREATE_OTR_DRIVER: 'CREATE_OTR_DRIVER'
};

export const formTemplates = [
    {
        id: 'frm-tpl-crt-whs',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT,
        questions: createIncidentQuestions
    },
    {
        id: 'frm-tpl-crt-aws',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT_AWS,
        questions: awsCreateIncidentQuestions
    },
    {
        id: 'frm-tpl-crt-whs-papi',
        name: 'What do you want to record?',
        type: FORM_TYPES.CREATE_INCIDENT_PAPI,
        questions: createIncidentWithPapiQuestions
    },
    {
        id: 'frm-tpl-cntr',
        name: 'Contractor Info',
        type: FORM_TYPES.CREATE_CONTRACTOR,
        sections: createNonAmazonian
    },
    {
        id: 'frm-tpl-cntr-otr',
        name: 'Contractor Info',
        type: FORM_TYPES.CREATE_OTR_DRIVER,
        sections: createOTRNonAmazonian
    }
];
