var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { format } from 'date-fns';
import { ATTACHMENT_STATUS_LANG_ID } from '../../constants';
import { DATE_DISPLAY_FORMAT } from '../dates';
import { AttachmentTypeLangId } from '../../../components/FileAttachments/constants';
var DELIMITER = '=';
export var mapAttachmentValue = function (data, context) {
    var intl = context.intl;
    var formatValue = function (fieldValue) {
        var valueKey = fieldValue.valueKey;
        // Extract key value pairs, remove invalid values. string.split returns array of original string if no match
        var metadataStrings = valueKey.split(/FileMetaData/).filter(function (value) { return !!value && value !== valueKey; });
        var resultValueKey = '';
        if (valueKey && metadataStrings.length > 0) {
            // Excludes first element
            metadataStrings.forEach(function (metadataString) {
                // Extract key value pairs, remove all whitespace
                var cleanString = metadataString.replace(/\s|\(|\)/g, '');
                var metadataKeyValuePairs = cleanString.split(',');
                var map = {};
                metadataKeyValuePairs
                    .filter(function (value) { return value.includes(DELIMITER); }) // Remove non-key value pairs
                    .forEach(function (pair) {
                    var _a = pair.split(DELIMITER), key = _a[0], value = _a[1];
                    map[key] = value;
                });
                var valueKey = intl.formatMessage({ id: 'ehs_incidents_msg_fileMetadataFormatted' }, {
                    fileName: map.fileName,
                    uploadedAt: format(new Date(map.uploadedAt), DATE_DISPLAY_FORMAT),
                    description: map.description,
                    uploadedBy: map.uploadedBy,
                    type: map.type && AttachmentTypeLangId[map.type] ?
                        intl.formatMessage({ id: AttachmentTypeLangId[map.type] }) :
                        map.type
                });
                resultValueKey = resultValueKey.concat("".concat(valueKey, " "));
            });
            return __assign(__assign({}, fieldValue), { valueKey: resultValueKey });
        }
        return fieldValue;
    };
    return __assign(__assign({}, data), { fieldKey: intl.formatMessage({
            id: ATTACHMENT_STATUS_LANG_ID[data.fieldKey]
        }), previousValues: data.previousValues.map(formatValue), updatedValues: data.updatedValues.map(formatValue) });
};
