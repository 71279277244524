import { AttachmentTypeDescriptionLangId, AttachmentTypeLangId } from '../FileAttachments/constants';
// prettier-ignore
export var buildHelpPanelContentSectionsFromAllowedAttachmentTypes = function (allowedAttachmentTypes) {
    return (allowedAttachmentTypes
        ? allowedAttachmentTypes.map(function (attachmentType) {
            var _a, _b;
            return ({
                title: (_a = AttachmentTypeLangId[attachmentType]) !== null && _a !== void 0 ? _a : attachmentType,
                description: (_b = AttachmentTypeDescriptionLangId[attachmentType]) !== null && _b !== void 0 ? _b : 'ehs_incidents_msg_noDescriptionAvailable'
            });
        })
        : []);
};
