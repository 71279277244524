/* istanbul ignore file */
/**
 * @todo This file is copied from austin-core
 * This can be removed when we are properly migrated to use common components/hooks for file service v2.
 */
export var PAYLOAD_PROCESSING_TYPE;
(function (PAYLOAD_PROCESSING_TYPE) {
    PAYLOAD_PROCESSING_TYPE["IMAGE_THUMBNAIL"] = "IMAGE_THUMBNAIL";
    PAYLOAD_PROCESSING_TYPE["NONE"] = "NONE";
    PAYLOAD_PROCESSING_TYPE["STORAGE_NORMAL_LIMITS"] = "STORAGE_NORMAL_LIMITS";
})(PAYLOAD_PROCESSING_TYPE || (PAYLOAD_PROCESSING_TYPE = {}));
export var FILE_ERROR_TYPE;
(function (FILE_ERROR_TYPE) {
    FILE_ERROR_TYPE[FILE_ERROR_TYPE["SELECTION"] = 0] = "SELECTION";
    FILE_ERROR_TYPE[FILE_ERROR_TYPE["UPLOAD"] = 1] = "UPLOAD";
})(FILE_ERROR_TYPE || (FILE_ERROR_TYPE = {}));
export var FILE_TYPE;
(function (FILE_TYPE) {
    FILE_TYPE["GENERAL_STORAGE"] = "GENERAL_STORAGE";
    FILE_TYPE["LARGE_IMAGE"] = "LARGE_IMAGE";
    FILE_TYPE["MAX_IMAGE"] = "MAX_IMAGE";
    FILE_TYPE["MEDIUM_IMAGE"] = "MEDIUM_IMAGE";
    FILE_TYPE["SMALL_IMAGE"] = "SMALL_IMAGE";
})(FILE_TYPE || (FILE_TYPE = {}));
