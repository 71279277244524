/* istanbul ignore file */
import { subDays, intervalToDuration } from 'date-fns';
import { get } from 'lodash';

/**
 * Helper method to format a number (total days) to readable localized duration i.e. 3 years, 4 months, 20 days
 *
 * @param {Array} value
 * answerList from form, containing single value total days to format
 *
 * @param {Object} intl
 * Localization format object
 * @param {Function} intl.formatMessage
 *
 * @returns {Array} formatted answer value
 */
function formatDuration(value, intl) {
    if (!value?.length) {
        return value;
    }

    const totalDays = parseInt(value, 10);
    if (Number.isNaN(totalDays) || totalDays < 0) {
        return value;
    }

    // get a duration object from current date { years, months, days }
    const today = new Date();
    const duration = intervalToDuration({
        start: subDays(today, totalDays),
        end: today
    });

    const { years = 0, months = 0, days = 0 } = duration;
    const formattedValues = [];
    if (years > 0) {
        formattedValues.push(
            intl.formatMessage(
                {
                    id: 'ehs_incidents_label_dateDurationYears',
                    defaultMessage:
                        '{years, plural, one {# year} other {# years}}'
                },
                { years }
            )
        );
    }

    if (months > 0) {
        formattedValues.push(
            intl.formatMessage(
                {
                    id: 'ehs_incidents_label_dateDurationMonths',
                    defaultMessage:
                        '{months, plural, one {# month} other {# months}}'
                },
                { months }
            )
        );
    }

    if (days > 0) {
        formattedValues.push(
            intl.formatMessage(
                {
                    id: 'ehs_incidents_label_dateDurationDays',
                    defaultMessage: '{days, plural, one {# day} other {# days}}'
                },
                { days }
            )
        );
    }

    return formattedValues.join(', ');
}

/**
 * Identify fn, returns passed value
 * @param {Any} value
 * @returns value
 */
function defaultFormatter(value) {
    return value;
}

const ANSWER_FORMAT_HELPERS = {
    duration: formatDuration
};

/**
 * Returns a value formatter method for given type
 * @param {String} type
 * ANSWER_FORMAT_HELPER type
 * @returns {Function}
 */
export function getAnswerFormatter(type) {
    return get(ANSWER_FORMAT_HELPERS, type, defaultFormatter);
}
