var _a;
export var IncidentRiskTypes;
(function (IncidentRiskTypes) {
    IncidentRiskTypes["INITIAL"] = "INITIAL";
    IncidentRiskTypes["POST_CONTROL"] = "POST_CONTROL";
})(IncidentRiskTypes || (IncidentRiskTypes = {}));
export var IncidentsResourceNames = {
    ACTIONS: 'Actions',
    FOLLOWUP: 'Followup',
    FROI: 'FROI',
    ICARE: 'Icare',
    INITIAL_ENCOUNTER: 'InitialEncounter',
    MEDICAL_ATTACHMENTS: 'MedicalAttachments',
    RCA: 'RCA',
    REGULATORY: 'Regulatory',
    PHASE_QUESTIONS_LOCK: 'PhaseQuestionsLock',
    PARk: 'PARk',
    INCIDENT: 'Incident',
    REGULATORY_REPORTING_BULK: 'RegulatoryReportingBulk',
    REGULATORY_REPORTING: 'RegulatoryReporting',
    SEARCH_LIST: 'SearchList'
};
var INCIDENT_STATUS = {
    APPROVED: 'APPROVED',
    ARCHIVED: 'ARCHIVED',
    READY: 'READY',
    DELETED: 'DELETED',
    DRAFT: 'DRAFT',
    CANCELLED: 'CANCELLED',
    IN_PROGRESS: 'IN_PROGRESS',
    FOR_REVIEW: 'FOR_REVIEW',
    CLOSED: 'CLOSED',
    PROCESSING: 'PROCESSING'
};
export var SeverityLevel;
(function (SeverityLevel) {
    SeverityLevel["A"] = "A";
    SeverityLevel["B"] = "B";
    SeverityLevel["C"] = "C";
    SeverityLevel["D"] = "D";
    SeverityLevel["PENDING"] = "PENDING";
})(SeverityLevel || (SeverityLevel = {}));
// Incident AppConfig
export var IncidentFeatureFlag;
(function (IncidentFeatureFlag) {
    IncidentFeatureFlag["ENABLE_GET_INCIDENT_FORMS"] = "enableGetIncidentForms";
    IncidentFeatureFlag["MULTI_PERSON_INCIDENT"] = "multiPersonIncident";
    IncidentFeatureFlag["ENABLE_OTR_FEATURES"] = "enableOTRFeatures";
    IncidentFeatureFlag["ENABLE_PARK"] = "enablePARk";
})(IncidentFeatureFlag || (IncidentFeatureFlag = {}));
// Feature flags AppConfig
export var IncidentAppConfigAppName;
(function (IncidentAppConfigAppName) {
    IncidentAppConfigAppName["INCIDENT_SERVICE"] = "IncidentService";
    IncidentAppConfigAppName["INCIDENT_DEPERSONALIZATION"] = "IncidentDepersonalization";
})(IncidentAppConfigAppName || (IncidentAppConfigAppName = {}));
export var IncidentAppConfigConfigName;
(function (IncidentAppConfigConfigName) {
    // IncidentService
    IncidentAppConfigConfigName["SIMPLIFIED_IRF"] = "SimplifiedIRF";
    IncidentAppConfigConfigName["SIMPLIFIED_FORM_REGULATORY_PHASE"] = "SimplifiedFormRegulatoryPhase";
    IncidentAppConfigConfigName["SIMPLIFIED_FORM_MEDICAL_SELECTION"] = "SimplifiedFormMedicalSelection";
    IncidentAppConfigConfigName["OTR"] = "OnTheRoad";
    // PARk
    IncidentAppConfigConfigName["PARk"] = "PARk";
    // IncidentDepersonalization
    IncidentAppConfigConfigName["INCIDENT_DEPERSONALIZATION_CCTV"] = "IncidentDepersonalizationCCTV";
})(IncidentAppConfigConfigName || (IncidentAppConfigConfigName = {}));
export var IncidentAppConfigEnvName;
(function (IncidentAppConfigEnvName) {
    // IncidentService
    IncidentAppConfigEnvName["SERVICE_ENVIRONMENT"] = "ServiceEnvironment";
    // IncidentDepersonalization
    IncidentAppConfigEnvName["INCIDENT_DEPERSONALIZATION_ENVIRONMENT"] = "IncidentDepersonalizationEnvironment";
})(IncidentAppConfigEnvName || (IncidentAppConfigEnvName = {}));
export var OTR_APP_CONFIG_CONFIGURATION = {
    appName: IncidentAppConfigAppName.INCIDENT_SERVICE,
    configName: IncidentAppConfigConfigName.OTR,
    envName: IncidentAppConfigEnvName.SERVICE_ENVIRONMENT
};
export var PARK_APP_CONFIG_CONFIGURATION = {
    appName: IncidentAppConfigAppName.INCIDENT_SERVICE,
    configName: IncidentAppConfigConfigName.PARk,
    envName: IncidentAppConfigEnvName.SERVICE_ENVIRONMENT
};
/**
 * Add a new feature flag, update this map to include its app config config
 */
export var IncidentAppConfigFeatureFlagConfigMap = (_a = {},
    _a[IncidentFeatureFlag.MULTI_PERSON_INCIDENT] = OTR_APP_CONFIG_CONFIGURATION,
    _a[IncidentFeatureFlag.ENABLE_GET_INCIDENT_FORMS] = OTR_APP_CONFIG_CONFIGURATION,
    _a[IncidentFeatureFlag.ENABLE_OTR_FEATURES] = OTR_APP_CONFIG_CONFIGURATION,
    _a[IncidentFeatureFlag.ENABLE_PARK] = PARK_APP_CONFIG_CONFIGURATION,
    _a);
