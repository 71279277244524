import { get, identity as defaultMap } from 'lodash';
import {
    isFormQuestion,
    mapStatusValue,
    mapQuestionChanges
} from './formFields';
import { getContextForForm } from './formHistoryContext';
import { mapAttachmentValue } from './fileAttachment';

/**
 * Incident / Form model fields
 * Additonal entityType handling can be set here
 */
const FORMATTERS_BY_FIELD_KEY = {
    STATUS: mapStatusValue,
    ATTACHMENT_ADDED: mapAttachmentValue,
    ATTACHMENT_DELETED: mapAttachmentValue
};

const CHANGE_ENTITY_TYPES = {
    FORM: 'Form'
};

const contextHandlers = {
    [CHANGE_ENTITY_TYPES.FORM]: getContextForForm
};

/**
 * Gets a defined context handler for the given entityType.
 * Certain entities will need to resolve additional data before mapping changes.
 *
 * @param {Array} changes
 * List of changes for the entityId
 *
 * @param {String} entityType
 * The type of entity changes apply to.
 *
 * @param {Object} context
 * Passed data from for the given entity type. Used to resolve the change fieldKey display value.
 *
 * @returns {Object}
 * Updated context for the given entityType
 */
export async function getContextForEntity(changes, entityType, context = {}) {
    let updatedContext = context;
    const contextHandler = get(contextHandlers, entityType);
    if (contextHandler) {
        updatedContext = await contextHandler(changes, context);
    }

    return updatedContext;
}

/**
 * Formats data for activity history
 * @param {Array} changes
 * List of changes for the entityId
 *
 * @param {String} entityType
 * The type of entity changes apply to.
 *
 * @param {Object} context
 * Passed data from for the given entity type. Used to resolve the change fieldKey display value.
 *
 * @returns {Array}
 * Mapped changes to be displayed
 */
export function formatActivityHistoryChanges(
    changes,
    entityType,
    context = {}
) {
    return changes.map((data) => {
        let updatedData = {};
        const { fieldKey } = data;

        try {
            const mappingFn = isFormQuestion(fieldKey, entityType, context)
                ? mapQuestionChanges
                : get(
                      FORMATTERS_BY_FIELD_KEY,
                      `${fieldKey}`.toUpperCase(),
                      defaultMap
                  );

            if (mappingFn) {
                updatedData = mappingFn(data, context);
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error mapping changes', err);
        }

        return {
            ...data,
            ...updatedData
        };
    });
}
