import { get } from 'lodash';
import { IncidentsService } from '../../../services/Incidents';
import { FIELD_TYPES } from '../../../components/fields/fieldTypes';

/**
 * Gets person data for multiple personIds and timestamp
 * @param {Array} personIds
 * List of personIds (UUID) to fetch data for
 *
 * @param {ISO Date} timestamp
 * Point in time for incident and alias in Historical people service
 *
 * @returns {Object}
 * Object by personId with resolved person data fields
 * @prop {String} alias
 * @prop {String} firstName
 * @prop {String} lastName
 */
export async function getPeopleDataById(personIds, timestamp) {
    const personData = {};
    try {
        const { getPersonDataByIds } = IncidentsService();
        if (personIds.length) {
            const results = await getPersonDataByIds({
                personIds,
                timestamp: timestamp || new Date().toISOString()
            });

            if (results) {
                Object.values(results).forEach((data) => {
                    if (data?.personId) {
                        personData[data.personId] = data;
                    }
                });
            }
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }

    return personData;
}

/**
 * Filters changes for fields containing a personId.  Checks user permission to view employee info from IncidentContext
 * before resolving user aliases from Historical People service. Adds resolved data to context.
 *
 * Context will be provided to changes map function to display relevant values in History table.
 *
 * @param {Array} changes
 * @param {Object} context
 * Data object with relevant fields to Entity history changes
 *
 * @returns {Object} context
 */
export async function getPersonIdContext(changes, context) {
    const updatedContext = {};
    const { questionMap, attributeAccess = {} } = context;
    const { canViewEmployeeInfo = false } = attributeAccess;

    // Don't map person data if user doesn't have permission to view
    if (!canViewEmployeeInfo) {
        return context;
    }

    // Filter changes for fields that contain personId
    const personIds = changes
        .filter(({ fieldKey }) => {
            const question = get(questionMap, fieldKey);
            return (
                question &&
                get(
                    question.answerTemplate,
                    'renderAs',
                    question.answerTemplate?.type
                ) === FIELD_TYPES.PERSON
            );
        })
        .reduce(
            (fieldKeys, { previousValues, updatedValues }) =>
                fieldKeys.concat(
                    [...previousValues, ...updatedValues].map(
                        ({ valueKey }) => valueKey
                    )
                ),

            []
        );

    if (personIds.length) {
        const personData = await getPeopleDataById(
            personIds,
            context?.incidentData?.incidentDateTime
        );
        updatedContext.personData = personData;
    }

    return {
        ...context,
        ...updatedContext
    };
}

/**
 * Add additional data to the context object for the given EntityType.
 * Context will be provided to changes map function to display relevant values in History table.
 *
 * @param {Array} changes
 * @param {Object} context
 * Data object with relevant fields to Entity history changes
 *
 * @returns {Object} context
 */
const formContextHandlers = [getPersonIdContext];
export async function getContextForForm(
    changes,
    context = {},
    handlers = formContextHandlers
) {
    // Check for person id changes, Resolve alias if applicable
    const updatedContext = await Promise.all(
        handlers.map((fn) => fn.apply(null, [changes, context]))
    ).then((updates) =>
        updates.reduce(
            (updated, next) => ({
                ...updated,
                ...next
            }),
            {}
        )
    );

    return {
        ...context,
        ...updatedContext
    };
}
