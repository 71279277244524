var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { IncidentsService } from '../../services/Incidents';
export var SESSION_STORAGE_ENABLED_FEATURE_FLAGS_KEY = 'incidents_app_config_enabled_feature_flags';
/**
 * Store results from backend to session
 * @param flags
 */
var setEnabledFeatureFlagsToSession = function (flags) {
    if (flags === void 0) { flags = []; }
    sessionStorage.setItem(SESSION_STORAGE_ENABLED_FEATURE_FLAGS_KEY, JSON.stringify(flags));
};
/**
 * Retrieve stored enabled feature flags from session storage
 * @return {String[] | null}
 */
export var getEnabledFeatureFlagsFromSession = function () {
    var result = sessionStorage.getItem(SESSION_STORAGE_ENABLED_FEATURE_FLAGS_KEY);
    // If flags exist
    if (result) {
        return JSON.parse(result);
    }
    return null;
};
/**
 * Call backend to fetch AUSTIN incidents AppConfig feature flags and store them in session storage. Initialization
 * requires the following AppConfig config:
 *
 * 1) appName: Name of the app. Defined in enum IncidentAppConfigAppName
 * 2) configName: Configuration profile name. Defined in enum IncidentAppConfigConfigName
 * 3) envName: Environment name. Defined in enum IncidentAppConfigEnvName
 *
 * Can supply customized configuration. E.g.
 * const config = {
 *     appName: IncidentAppConfigAppName.INCIDENT_SERVICE,
 *     configName: IncidentAppConfigConfigName.SIMPLIFIED_FORM_MEDICAL_SELECTION,
 *     envName: IncidentAppConfigEnvName.SERVICE_ENVIRONMENT
 * };
 * const flagsEnabled = fetchFeatureFlags(config);
 *
 * Or use pre-defined configuration. E.g.
 * const flagsEnabled = fetchFeatureFlags(OTR_APP_CONFIG_CONFIGURATION);
 * @param configs
 */
export var fetchFeatureFlags = function (configs) {
    if (configs === void 0) { configs = []; }
    return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all(configs.map(function (config) {
                        return IncidentsService().getEnabledFeatureFlags(config);
                    }))];
                case 1:
                    result = _a.sent();
                    setEnabledFeatureFlagsToSession(result.flatMap(function (res) { return __spreadArray([], res, true); }));
                    return [2 /*return*/];
            }
        });
    });
};
/**
 * Test if a flag is enabled from session storage. Accepts flag from IncidentFeatureFlag enum.
 * Example:
 *     const isMultiPersonIncidentEnabled = isFeatureEnabled(IncidentFeatureFlag.MULTI_PERSON_INCIDENT);
 *
 * @param {String} flag
 * @return {Boolean}
 */
export var isFeatureFlagEnabled = function (flag) {
    var localFeatureFlags = getEnabledFeatureFlagsFromSession() || [];
    return localFeatureFlags.includes(flag);
};
