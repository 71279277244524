import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useIntl } from '@amzn/austin-core';
import IncidentSkeleton from './IncidentSkeleton';
import useSiteLaunchCheck from '../common/hooks/useSiteLaunchCheck';

const IncidentSiteLaunchCheck = memo(
    ({ alwaysRenderChildren, children, renderLoading, siteId }) => {
        const { intl } = useIntl();
        const {
            isLoading,
            getSiteLaunchData,
            launchDate,
            isSiteClosed,
            isSiteLaunched,
            siteClosureDate,
            siteName
        } = useSiteLaunchCheck();

        useEffect(() => {
            getSiteLaunchData(siteId);
        }, [siteId]);

        let loadingComponent;
        if (isLoading) {
            loadingComponent = renderLoading ? (
                renderLoading()
            ) : (
                <IncidentSkeleton />
            );
        }

        // Check if the conditions to render the children props are met
        // if consumer wants to handle child rendering itself, allow rendering based on alwaysRenderChildren prop
        // and passed siteData props
        const shouldRenderChildren =
            alwaysRenderChildren || (!isLoading && isSiteLaunched);

        let accessMessage;
        if (!isSiteLaunched && siteName) {
            accessMessage = launchDate
                ? intl.formatMessage(
                      { id: 'ehs_incidents_msg_siteLaunchDate' },
                      { siteName, launchDate }
                  )
                : intl.formatMessage(
                      { id: 'ehs_incidents_msg_siteIncidentsNotAvailable' },
                      { siteName }
                  );
        }

        return (
            <>
                {isLoading && loadingComponent}
                {!isLoading && accessMessage && (
                    <Typography align="center">{accessMessage}</Typography>
                )}
                {shouldRenderChildren &&
                    React.Children.map(children, (child) =>
                        React.cloneElement(child, {
                            isSiteLaunched,
                            isLoading,
                            isSiteClosed,
                            siteClosureDate,
                            siteName,
                            launchDate
                        })
                    )}
            </>
        );
    }
);

IncidentSiteLaunchCheck.propTypes = {
    alwaysRenderChildren: PropTypes.bool,
    renderLoading: PropTypes.func,
    siteId: PropTypes.string
};

IncidentSiteLaunchCheck.defaultProps = {
    alwaysRenderChildren: false,
    renderLoading: undefined,
    siteId: undefined
};

export default IncidentSiteLaunchCheck;
