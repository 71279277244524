var _a, _b, _c;
/* istanbul ignore file */
import { ACTION_STATUS, RISK_LIKELINESS, RISK_SEVERITY } from '@amzn/austin-module-actions';
export var ACTION_SAVE_STATUS = {
    DELETE: 'Delete',
    NEW: 'New',
    NEWDRAFT: 'NewDraft',
    UNMODIFIED: 'Unmodified',
    UPDATE: 'Update'
};
export var ACTION_STATUS_LAN_KEY_MAP = (_a = {},
    _a[ACTION_STATUS.CANCELLED] = 'ehs_common_label_cancelled',
    _a[ACTION_STATUS.CLOSED] = 'ehs_common_label_closed',
    _a[ACTION_STATUS.DRAFT] = 'ehs_common_label_draft',
    _a[ACTION_STATUS.OPEN] = 'ehs_common_label_inProgress',
    _a[ACTION_STATUS.PENDING_RISK_ASSESSMENT] = 'ehs_common_action_assess',
    _a[ACTION_STATUS.PRE_CREATE] = 'ehs_common_label_preCreate',
    _a[ACTION_STATUS.PRE_CREATE_CLOSED] = 'ehs_common_label_preCreateClosd',
    _a[ACTION_STATUS.PROCESSING] = 'ehs_common_label_processing',
    _a[ACTION_STATUS.REVIEW] = 'ehs_common_label_validate',
    _a[ACTION_STATUS.VERIFY] = 'ehs_common_label_verify',
    _a);
export var ACTION_TYPE = {
    CORRECTIVE_ACTION: 'CORRECTIVE_ACTION',
    FINDING: 'FINDING',
    ROOT_CAUSE_ANALYSIS: 'ROOT_CAUSE_ANALYSIS'
};
export var EXTERNAL_SYSTEM_ID;
(function (EXTERNAL_SYSTEM_ID) {
    EXTERNAL_SYSTEM_ID["FINDING"] = "FINDING";
    EXTERNAL_SYSTEM_ID["INCIDENTS"] = "INCIDENTS";
})(EXTERNAL_SYSTEM_ID || (EXTERNAL_SYSTEM_ID = {}));
export var ISSUE_MUTATE_ACTION = {
    ACCEPT_PLAN: 'ACCEPT_PLAN',
    REJECT_PLAN: 'REJECT_PLAN',
    REJECT_RESULTS: 'REJECT_RESULTS',
    RESOLVE: 'RESOLVE',
    SUBMIT: 'SUBMIT',
    SUBMIT_PLAN: 'SUBMIT_PLAN'
};
export var ISSUE_LIKELINESS_KEY_MAP = (_b = {},
    _b[RISK_LIKELINESS.HIGHLY_UNLIKELY] = 'ehs_actionsCommon_label_veryUnlikely',
    _b[RISK_LIKELINESS.LIKELY] = 'ehs_actionsCommon_label_likely',
    _b[RISK_LIKELINESS.POSSIBLE] = 'ehs_actionsCommon_label_possible',
    _b[RISK_LIKELINESS.UNDETERMINED] = 'ehs_actionsCommon_label_undetermined',
    _b[RISK_LIKELINESS.UNLIKELY] = 'ehs_actionsCommon_label_unlikely',
    _b[RISK_LIKELINESS.VERY_LIKELY] = 'ehs_actionsCommon_label_veryLikely',
    _b);
export var ISSUE_SEVERITY_KEY_MAP = (_c = {},
    _c[RISK_SEVERITY.EXTENSIVE] = 'ehs_actionsCommon_label_extensive',
    _c[RISK_SEVERITY.MAJOR] = 'ehs_actionsCommon_label_major',
    _c[RISK_SEVERITY.MINOR] = 'ehs_actionsCommon_label_minor',
    _c[RISK_SEVERITY.NEGLIGIBLE] = 'ehs_actionsCommon_label_negligible',
    _c[RISK_SEVERITY.SIGNIFICANT] = 'ehs_actionsCommon_label_significant',
    _c[RISK_SEVERITY.UNDETERMINED] = 'ehs_actionsCommon_label_undetermined',
    _c);
export var TERM = {
    IMMEDIATE: 'IMMEDIATE',
    LONG: 'LONG'
};
