var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* istanbul ignore file */
import { v1 as uuidv1 } from 'uuid';
import { get } from 'lodash';
import { endOfDay } from 'date-fns';
import { COMPOSE_OPERATOR, DATE_PICKER_FORMAT, FILTER_TYPE, QUERY_TYPE, convertToDateObject, executeRequest, generateQuery, getDateStringForPicker, retrieveAttachments, updateLinkingIdToId } from '@amzn/austin-core';
import { ACTION_STATUS } from '@amzn/austin-module-actions';
import { ACTION_SAVE_STATUS, ACTION_TYPE } from '../constants';
import { getDueDateInformation, isExtensionRequired } from './due-dates';
import { ISSUE_LIKELINESS_RATING, ISSUE_SEVERITIES_RATING, getOverallCriticality } from './risk-assessment';
var actionItemFields = "\n    actionGroupId,\n    actionType,\n    approvalDecisions {\n        decision,\n        status\n    },\n    assets {\n        id,\n        type\n    },\n    assignee,\n    comments {\n        comment,\n        date,\n        user,\n        type\n    },\n    completionDate,\n    createdAt,\n    createdBy,\n    criticality,\n    description,\n    dueDate,\n    employees,\n    extensions {\n        approvalDate,\n        approver,\n        justification,\n        requestedDate,\n        status\n    },\n    freeFormEmployees,\n    freeFormLocations,\n    gensuiteIssues,\n    hazard {\n        text,\n        type\n    },\n    history {\n        systemComment,\n        timestamp,\n        user\n    },\n    id,\n    isOverdue,\n    name,\n    originalDueDate,\n    owner,\n    ownerType,\n    products,\n    riskAssessments {\n        choices {\n            category,\n            likeliness,\n            severity\n        }\n        date,\n        user\n    },\n    siteId,\n    sources {\n        id,\n        name,\n        type\n    },\n    status,\n    term,\n    updatedAt,\n    version,\n    workflowProgress {\n        steps {\n          completion,\n          completionDate,\n          group,\n          status,\n          user\n        }\n    }\n";
var bulkCreateIssues = "mutation bulkCreateIssues(\n    $issues: [IssueCreateInput!]!\n){\n    issues: bulkCreateIssues(\n        input: {\n            issues: $issues\n        }\n    ) {\n        error\n        succeeded {\n            actions { ".concat(actionItemFields, " },\n            issue { ").concat(actionItemFields, " }\n        }\n    }\n}");
var searchActionItemsQuery = "query searchActionItems(\n    $pageInfo: PageInfoInput!,\n    $compose: [ComposeInput!],\n    $queryFilters: [QueryFilterInput!],\n    $sortInfos: [SortInfoInput!]\n) {\n    results: searchActionItems(\n        input: {\n            pageInfo: $pageInfo,\n            compose: $compose,\n            queryFilters: $queryFilters,\n            sortInfos: $sortInfos\n        }\n    ) {\n        count\n        entities {\n            actionType,\n            assignee,\n            completionDate,\n            createdAt,\n            createdBy,\n            criticality,\n            dueDate,\n            hazard {\n                text,\n                type\n            }\n            id,\n            isOverdue,\n            name,\n            ownerType,\n            riskAssessments {\n                choices {\n                    category,\n                    likeliness,\n                    severity\n                }\n                date,\n                user\n            },\n            version,\n            siteId,\n            sources {\n                id,\n                name,\n                type\n            }\n            status,\n            term,\n            updatedAt,\n\n        }\n    }\n}";
/**
 * Creates IssueCreateInput model which is passed as input for createIssue mutation.
 *
 * @private
 */
function actionMapForCreateMutation(action) {
    return __assign({ actionGroupId: action.actionGroupId, comments: getMappedComments(action.comments), description: action.description ? action.description : null, dueDate: endOfDay(convertToDateObject(action.dueDate, DATE_PICKER_FORMAT)), immediatelyResolved: action.immediatelyResolved ||
            action.status === ACTION_STATUS.PRE_CREATE_CLOSED ||
            action.status === ACTION_STATUS.CLOSED, isPreCreate: !!action.isPreCreate, name: action.name, owner: action.assignee, parent: action.parent, term: action.term }, getExtensionData(action));
}
/**
 * @private
 */
function actionMapForUpdateMutation(action, assigneeChangeOnly) {
    return assigneeChangeOnly
        ? {
            assignee: action.assignee,
            id: action.id,
            version: action.version
        }
        : {
            action: action.action || null,
            assignee: action.assignee,
            comments: getMappedComments(action.comments),
            description: action.description || null,
            dueDate: endOfDay(convertToDateObject(action.dueDate, DATE_PICKER_FORMAT)),
            gensuiteIssues: action.gensuiteIssues.length
                ? action.gensuiteIssues
                : null,
            id: action.id,
            issues: action.issues.length > 0 ? action.issues : null,
            name: action.name,
            status: [
                ACTION_STATUS.PRE_CREATE,
                ACTION_STATUS.PRE_CREATE_CLOSED
            ].includes(action.status)
                ? action.status
                : null,
            term: action.term,
            version: action.version
        };
}
export function actionNormalization(action) {
    var _a = action.comments, comments = _a === void 0 ? [] : _a, _b = action.description, description = _b === void 0 ? '' : _b, dueDate = action.dueDate, status = action.status;
    var convertedDueDate = getDateStringForPicker(dueDate);
    return __assign(__assign({}, action), { comments: commentsNormalization(comments), description: description, dueDate: convertedDueDate, dueDateISO: dueDate, immediatelyResolved: status === ACTION_STATUS.PRE_CREATE_CLOSED, isSaved: true });
}
/**
 * Method that will normalize the comments to what the presentation layer needs
 *
 * @private
 */
function commentsNormalization(comments) {
    return comments.map(function (comment) { return (__assign(__assign({}, comment), { id: uuidv1(), isSaved: true })); });
}
export function createActionGroups(groupInputs) {
    return __awaiter(this, void 0, void 0, function () {
        var inputs, _a, query, variables;
        return __generator(this, function (_b) {
            inputs = groupInputs.map(function (input) { return ({ input: input }); });
            _a = generateQuery('mutation CreateActionGroup', '$input: CreateActionGroupInput!', "createActionGroup (\n            input: $input\n        ) {\n            actionIds\n            externalId\n            id\n        }", inputs), query = _a.query, variables = _a.variables;
            return [2 /*return*/, executeRequest(query, variables).then(function (response) { return Object.values(response); })];
        });
    });
}
export function deleteActions(deletedActionIds) {
    return __awaiter(this, void 0, void 0, function () {
        var bulkDeleteActions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    bulkDeleteActions = "mutation bulkDeleteActions(\n        $input: BulkDeleteInput!\n    ) {\n        failedActions: bulkDeleteActions(\n            input: $input\n        ) {\n            failed\n        }\n    }";
                    return [4 /*yield*/, executeRequest(bulkDeleteActions, {
                            input: { entityIds: deletedActionIds }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getActions(actionIds) {
    return __awaiter(this, void 0, void 0, function () {
        var queryFilters, pageInfo, entities;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryFilters = {
                        filterType: FILTER_TYPE.MUST,
                        queryParams: {
                            field: 'id',
                            queryType: QUERY_TYPE.FUZZY_MATCH,
                            value: actionIds
                        }
                    };
                    pageInfo = {
                        from: 0,
                        size: 1000
                    };
                    return [4 /*yield*/, executeRequest(searchActionItemsQuery, {
                            pageInfo: pageInfo,
                            queryFilters: queryFilters
                        })];
                case 1:
                    entities = (_a.sent()).results.entities;
                    return [2 /*return*/, entities];
            }
        });
    });
}
export function getActionGroupsByExternalId(externalIds) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, executeRequest("query GetActionGroupsByExternalIds($input: GetActionGroupsByExternalIdsInput!) {\n            getActionGroupsByExternalIds(input: $input) {\n                actionGroups {\n                    actionIds\n                    externalId\n                    id\n                }\n            }\n        }", { input: { externalIds: externalIds } }).then(function (response) {
                    return response.getActionGroupsByExternalIds.actionGroups;
                })];
        });
    });
}
export function getCalculatedCriticalityScore(issueRiskChoices) {
    var _a;
    var maxSeverity = Math.max.apply(Math, issueRiskChoices.map(function (_a) {
        var _b;
        var severity = _a.severity;
        return ((_b = ISSUE_SEVERITIES_RATING.find(function (item) { return item.value === severity; })) === null || _b === void 0 ? void 0 : _b.rating) || 0;
    }));
    var likelinessCalculatedRating = ((_a = ISSUE_LIKELINESS_RATING.find(function (item) { return item.value === issueRiskChoices[0].likeliness; })) === null || _a === void 0 ? void 0 : _a.rating) || 0;
    return Math.round(maxSeverity * likelinessCalculatedRating);
}
/**
 * @private
 */
function getExtensionData(action) {
    var extensionData = {};
    var dueDate = action.dueDate, findingCriticality = action.findingCriticality, justification = action.justification;
    var dueDateInfo = getDueDateInformation(action, findingCriticality);
    if (isExtensionRequired(dueDateInfo.standard, dueDate)) {
        extensionData = {
            dueDate: endOfDay(convertToDateObject(dueDateInfo.standard, DATE_PICKER_FORMAT)),
            extensionInput: {
                justification: justification,
                requestedDate: endOfDay(convertToDateObject(dueDate, DATE_PICKER_FORMAT))
            }
        };
    }
    return extensionData;
}
/**
 * Method that will extract the comments and format the comment properties to what the mutations expects
 *
 * @private
 */
function getMappedComments(comments) {
    return comments && comments.length > 0 && Boolean(comments[0].comment)
        ? comments.map(function (_a) {
            var comment = _a.comment, date = _a.date, user = _a.user, type = _a.type;
            return ({
                comment: comment,
                date: date,
                type: type,
                user: user
            });
        })
        : null;
}
function getNewEmptyActionCommon() {
    return {
        assets: [],
        assignee: '',
        comments: [],
        createdAt: new Date(),
        employees: [],
        hazard: [],
        images: [],
        immediatelyResolved: false,
        linkingId: uuidv1(),
        name: '',
        owner: '',
        products: [],
        saveStatus: ACTION_SAVE_STATUS.NEW,
        siteId: '',
        sources: []
    };
}
export function getNewEmptyCorrectiveAction(actionData) {
    if (actionData === void 0) { actionData = {}; }
    return __assign(__assign(__assign({}, getNewEmptyActionCommon()), { actionType: ACTION_TYPE.CORRECTIVE_ACTION, assignee: '', criticality: '', dueDate: null, extensions: [], files: [], gensuiteIssues: [], id: '', issues: [], overallCriticality: 0, parentLinkingId: '', status: '', term: '', verificationValidationValue: '' }), actionData);
}
export function getNewEmptyIssue(issueData) {
    if (issueData === void 0) { issueData = {}; }
    return __assign(__assign(__assign({}, getNewEmptyActionCommon()), { actions: [], isHazardFromSource: false, isPreCreate: true, riskAssessments: [] }), issueData);
}
/**
 * @private
 * @param {array} sources
 * @param {object} sourceCapture
 * @returns {array}
 */
function getSources(sources, sourceCapture) {
    var derivedSources = [];
    // If the source was selected, then we need to update the sources object, if the source was editable, then it is
    // safe to replace with new selection.
    if (sourceCapture && sourceCapture.selectedSource) {
        var mappedSource_1 = {
            id: sourceCapture.id || sourceCapture.selectedSource,
            name: sourceCapture.name || sourceCapture.selectedSource,
            type: sourceCapture.selectedSource
        };
        var existingSourceIndexId = derivedSources.findIndex(function (sourceItem) { return sourceItem.type === mappedSource_1.type; });
        if (existingSourceIndexId >= 0) {
            derivedSources[existingSourceIndexId] = mappedSource_1;
        }
        else {
            derivedSources.push(mappedSource_1);
        }
    }
    else {
        // Grab only the necesary fields from the existing sources
        derivedSources = sources.reduce(function (res, source) { return __spreadArray(__spreadArray([], res, true), [
            {
                id: source.id,
                name: source.name,
                type: source.type
            }
        ], false); }, []);
    }
    return derivedSources;
}
/**
 * @private
 */
function issueNormalization(finding) {
    var riskAssessments = get(finding, 'riskAssessments', []) || [];
    var normalizedRiskAssessments = riskAssessments.map(function (item) { return (__assign(__assign({}, item), { criticalityCalculation: getCalculatedCriticalityScore(item.choices), id: uuidv1() })); });
    return __assign(__assign({}, finding), { comments: commentsNormalization(finding.comments || []), description: finding.description || '', employees: finding.employees || [], isSaved: true, overallCriticality: getOverallCriticality(normalizedRiskAssessments), riskAssessments: normalizedRiskAssessments, siteId: finding.siteId });
}
/**
 * @private
 */
function issueMapForUpdateMutation(finding) {
    return {
        action: finding.action || null,
        assets: finding.assets || [],
        assignee: finding.assignee,
        comments: getMappedComments(finding.comments),
        description: finding.description || null,
        employees: finding.employees || [],
        freeFormEmployees: finding.freeFormEmployees || [],
        freeFormLocations: finding.freeFormLocations || [],
        hazard: finding.hazard,
        id: finding.id,
        name: finding.name,
        products: finding.products || [],
        // @ts-ignore false positive; "get" will return an array to map over
        riskAssessments: get(finding, 'riskAssessments', []).map(function (_a) {
            var choices = _a.choices, date = _a.date, user = _a.user;
            return ({
                choices: choices,
                date: date,
                user: user
            });
        }),
        sources: getSources(finding.sources, finding.sourceCapture),
        version: finding.version
    };
}
export function retrieveActionsBySource(sourceType, sourceIds, actionType) {
    return __awaiter(this, void 0, void 0, function () {
        var composeQueryFilters, queryFilters, pageInfo, results, findingIds, _a, images_1, files_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    composeQueryFilters = sourceIds.map(function (sourceId) { return ({
                        filterType: FILTER_TYPE.MUST,
                        queryParams: [
                            {
                                field: 'sources.type',
                                queryType: QUERY_TYPE.MATCH,
                                value: sourceType
                            },
                            {
                                field: 'sources.id',
                                queryType: QUERY_TYPE.MATCH,
                                value: sourceId
                            }
                        ]
                    }); });
                    queryFilters = actionType
                        ? {
                            filterType: FILTER_TYPE.MUST,
                            queryParams: {
                                field: 'actionType',
                                queryType: QUERY_TYPE.MATCH,
                                value: actionType
                            }
                        }
                        : undefined;
                    pageInfo = {
                        from: 0,
                        size: 1000
                    };
                    return [4 /*yield*/, executeRequest(searchActionItemsQuery, {
                            compose: {
                                composeOperator: COMPOSE_OPERATOR.OR,
                                queryFilters: composeQueryFilters
                            },
                            pageInfo: pageInfo,
                            queryFilters: queryFilters
                        })];
                case 1:
                    results = (_b.sent()).results;
                    if (!(results.count > 0)) return [3 /*break*/, 3];
                    findingIds = results.entities.map(function (_a) {
                        var id = _a.id;
                        return id;
                    });
                    return [4 /*yield*/, retrieveAttachments(findingIds)];
                case 2:
                    _a = _b.sent(), images_1 = _a.images, files_1 = _a.files;
                    return [2 /*return*/, results.entities.map(function (action) { return (__assign(__assign({}, action), { files: files_1[action.id] || [], images: images_1[action.id] || [] })); })];
                case 3: return [2 /*return*/, []];
            }
        });
    });
}
/**
 * @private
 */
function saveActions(actions) {
    return __awaiter(this, void 0, void 0, function () {
        var newActions, existingActions, deletedActions, updatedActions, _a, _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    newActions = actions.filter(function (action) { return action.saveStatus === ACTION_SAVE_STATUS.NEW; });
                    existingActions = actions.filter(function (action) { return action.saveStatus === ACTION_SAVE_STATUS.UPDATE; });
                    deletedActions = actions.filter(function (action) { return action.saveStatus === ACTION_SAVE_STATUS.DELETE; });
                    updatedActions = [];
                    if (!newActions.length) return [3 /*break*/, 2];
                    _b = (_a = updatedActions.push).apply;
                    _c = [updatedActions];
                    return [4 /*yield*/, saveNewActions(newActions)];
                case 1:
                    _b.apply(_a, _c.concat([(_g.sent())]));
                    _g.label = 2;
                case 2:
                    if (!existingActions.length) return [3 /*break*/, 4];
                    _e = (_d = updatedActions.push).apply;
                    _f = [updatedActions];
                    return [4 /*yield*/, updateActions(existingActions)];
                case 3:
                    _e.apply(_d, _f.concat([(_g.sent())]));
                    _g.label = 4;
                case 4:
                    if (!deletedActions.length) return [3 /*break*/, 6];
                    return [4 /*yield*/, deleteActions(deletedActions.map(function (_a) {
                            var id = _a.id;
                            return id;
                        }))];
                case 5:
                    _g.sent();
                    _g.label = 6;
                case 6: return [2 /*return*/, updatedActions];
            }
        });
    });
}
export function saveIssues(findings) {
    return __awaiter(this, void 0, void 0, function () {
        var newFindings, existingFindings, savedFindings, createdFindings, actions, unmodifiedActions_1, updatedActions_1, updatedFindings, updatedFindingsWithActions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newFindings = findings.filter(function (_a) {
                        var name = _a.name, saveStatus = _a.saveStatus;
                        return saveStatus === ACTION_SAVE_STATUS.NEW && name;
                    });
                    existingFindings = findings.filter(function (_a) {
                        var saveStatus = _a.saveStatus;
                        return saveStatus === ACTION_SAVE_STATUS.UPDATE;
                    });
                    savedFindings = findings.filter(function (_a) {
                        var saveStatus = _a.saveStatus;
                        return ![ACTION_SAVE_STATUS.NEW, ACTION_SAVE_STATUS.UPDATE].includes(saveStatus);
                    });
                    if (!newFindings.length) return [3 /*break*/, 3];
                    return [4 /*yield*/, saveNewFindings(newFindings)];
                case 1:
                    createdFindings = _a.sent();
                    savedFindings.push.apply(savedFindings, createdFindings);
                    // Update the temporary id from files and images
                    return [4 /*yield*/, updateFileStorageMetadata(createdFindings)];
                case 2:
                    // Update the temporary id from files and images
                    _a.sent();
                    _a.label = 3;
                case 3:
                    if (!existingFindings.length) return [3 /*break*/, 6];
                    actions = existingFindings.reduce(function (flatActions, finding) { return __spreadArray(__spreadArray([], flatActions, true), (finding.actions || []).map(function (action) { return (__assign(__assign({}, action), { findingCriticality: finding.criticality, parentLinkingId: finding.linkingId })); }), true); }, []);
                    unmodifiedActions_1 = actions.filter(function (action) {
                        return action.saveStatus === ACTION_SAVE_STATUS.UNMODIFIED;
                    });
                    return [4 /*yield*/, saveActions(actions)];
                case 4:
                    updatedActions_1 = _a.sent();
                    return [4 /*yield*/, updateFindings(existingFindings)];
                case 5:
                    updatedFindings = _a.sent();
                    updatedFindingsWithActions = updatedFindings.map(function (finding) { return (__assign(__assign({}, finding), { actions: __spreadArray(__spreadArray([], unmodifiedActions_1, true), updatedActions_1, true).filter(function (_a) {
                            var parentLinkingId = _a.parentLinkingId;
                            return parentLinkingId === finding.linkingId;
                        }) })); });
                    savedFindings.push.apply(savedFindings, updatedFindingsWithActions);
                    _a.label = 6;
                case 6: return [2 /*return*/, savedFindings];
            }
        });
    });
}
export function saveNewActions(newActions) {
    return __awaiter(this, void 0, void 0, function () {
        var actions, _a, query, variables, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    actions = newActions.map(function (action) { return ({
                        input: actionMapForCreateMutation(action)
                    }); });
                    _a = generateQuery('mutation createAction', '$input:ActionCreateInput!', "createAction (\n            input: $input\n        ) {\n            ".concat(actionItemFields, "\n        }"), actions), query = _a.query, variables = _a.variables;
                    return [4 /*yield*/, executeRequest(query, variables)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, Object.values(response).map(function (action, actionIndex) { return (__assign(__assign(__assign({}, newActions[actionIndex]), actionNormalization(action)), { saveStatus: ACTION_SAVE_STATUS.UNMODIFIED })); })];
            }
        });
    });
}
function saveNewFindings(newFindings) {
    return __awaiter(this, void 0, void 0, function () {
        var issues, succeeded, normalizedFindings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    issues = newFindings.map(function (finding) { return ({
                        action: finding.action || null,
                        // @ts-ignore false positive; array will always be returned
                        actions: get(finding, 'actions', []).map(function (action) {
                            return actionMapForCreateMutation(__assign(__assign({}, action), { findingCriticality: finding.criticality }));
                        }),
                        assets: get(finding, 'assets', []),
                        comments: getMappedComments(finding.comments),
                        description: get(finding, 'description', null),
                        employees: get(finding, 'employees', []),
                        hazard: Array.isArray(finding.hazard)
                            ? finding.hazard.filter(function (_a) {
                                var text = _a.text;
                                return text;
                            })
                            : [],
                        immediatelyResolved: [
                            ACTION_STATUS.PRE_CREATE_CLOSED,
                            ACTION_STATUS.CLOSED
                        ].includes(finding.status),
                        isPreCreate: finding.isPreCreate,
                        name: finding.name,
                        owner: finding.assignee || finding.owner,
                        products: get(finding, 'products', []),
                        requiresRca: !!finding.requiresRca,
                        riskAssessments: finding.riskAssessments.map(function (_a) {
                            var choices = _a.choices, date = _a.date, user = _a.user;
                            return ({
                                choices: choices,
                                date: date,
                                user: user
                            });
                        }),
                        scope: finding.scope,
                        siteId: finding.siteId,
                        sources: getSources(finding.sources, finding.sourceCapture)
                    }); });
                    return [4 /*yield*/, executeRequest(bulkCreateIssues, { issues: issues })];
                case 1:
                    succeeded = (_a.sent()).issues.succeeded;
                    normalizedFindings = newFindings.map(function (finding, index) { return (__assign(__assign({}, issueNormalization(__assign(__assign({}, finding), (succeeded[index] ? succeeded[index].issue : {})))), { actions: (finding.actions || []).map(function (action, actionIndex) { return (__assign(__assign(__assign({}, action), actionNormalization(get(succeeded, "[".concat(index, "].actions[").concat(actionIndex, "]"), {}))), { saveStatus: ACTION_SAVE_STATUS.UNMODIFIED })); }), saveStatus: ACTION_SAVE_STATUS.UNMODIFIED })); });
                    return [2 /*return*/, normalizedFindings];
            }
        });
    });
}
export function updateActionGroups(groupInputs) {
    return __awaiter(this, void 0, void 0, function () {
        var inputs, _a, query, variables;
        return __generator(this, function (_b) {
            inputs = groupInputs.map(function (input) { return ({ input: input }); });
            _a = generateQuery('mutation MutateActionGroup', '$input: MutateActionGroupInput!', "mutateActionGroup (\n            input: $input\n        ) {\n            actionIds\n            externalId\n            id\n        }", inputs), query = _a.query, variables = _a.variables;
            return [2 /*return*/, executeRequest(query, variables).then(function (response) { return Object.values(response); })];
        });
    });
}
/**
 * @private
 */
function updateActions(updatedActions, assigneeChangeOnly) {
    if (assigneeChangeOnly === void 0) { assigneeChangeOnly = false; }
    return __awaiter(this, void 0, void 0, function () {
        var actions, _a, query, variables, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    actions = updatedActions.map(function (action) { return ({
                        input: actionMapForUpdateMutation(action, assigneeChangeOnly)
                    }); });
                    _a = generateQuery('mutation mutateAction', '$input:ActionMutateInput!', "mutateAction (\n            input: $input\n        ) {\n            ".concat(actionItemFields, "\n        }"), actions), query = _a.query, variables = _a.variables;
                    return [4 /*yield*/, executeRequest(query, variables)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, Object.values(response).map(function (action, actionIndex) { return (__assign(__assign(__assign({}, updatedActions[actionIndex]), actionNormalization(action)), { saveStatus: ACTION_SAVE_STATUS.UNMODIFIED })); })];
            }
        });
    });
}
/**
 * @private
 */
function updateFindings(updatedFindings) {
    return __awaiter(this, void 0, void 0, function () {
        var issues, _a, query, variables, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    issues = updatedFindings.map(function (issue) { return ({
                        input: issueMapForUpdateMutation(issue)
                    }); });
                    _a = generateQuery('mutation mutateIssue', '$input:IssueMutateInput!', "mutateIssue (\n            input: $input\n        ) {\n            ".concat(actionItemFields, "\n        }"), issues), query = _a.query, variables = _a.variables;
                    return [4 /*yield*/, executeRequest(query, variables)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, Object.values(response).map(function (finding, index) { return (__assign(__assign(__assign({}, updatedFindings[index]), issueNormalization(finding)), { saveStatus: ACTION_SAVE_STATUS.UNMODIFIED })); })];
            }
        });
    });
}
/**
 * @private
 */
function updateFileStorageMetadata(findings) {
    return __awaiter(this, void 0, void 0, function () {
        var issueImages, issueFiles, actionImages, actionFiles, images, files;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    issueImages = findings.reduce(function (flatImages, finding) { return __spreadArray(__spreadArray([], flatImages, true), (finding.images || []).map(function (image) {
                        return (__assign(__assign({}, image), { linkingId: finding.linkingId, newId: finding.id }));
                    }), true); }, []);
                    issueFiles = findings.reduce(function (flatFiles, finding) { return __spreadArray(__spreadArray([], flatFiles, true), (finding.files || []).map(function (file) {
                        return (__assign(__assign({}, file), { linkingId: finding.linkingId, newId: finding.id }));
                    }), true); }, []);
                    actionImages = findings.reduce(function (flatImages, finding) { return __spreadArray(__spreadArray([], flatImages, true), (finding.actions || []).reduce(function (flatActionImages, action) {
                        var images = (action.images || []).map(function (image) {
                            return (__assign(__assign({}, image), { linkingId: action.linkingId, newId: action.id }));
                        });
                        return __spreadArray(__spreadArray([], flatActionImages, true), images, true);
                    }, []), true); }, []);
                    actionFiles = findings.reduce(function (flatFiles, finding) { return __spreadArray(__spreadArray([], flatFiles, true), (finding.actions || []).reduce(function (files, action) { return __spreadArray(__spreadArray([], files, true), (action.files || []).map(function (file) {
                        return (__assign(__assign({}, file), { newId: action.id }));
                    }), true); }, []), true); }, []);
                    images = __spreadArray(__spreadArray([], issueImages, true), actionImages, true).filter(
                    // @ts-ignore
                    function (_a) {
                        var id = _a.id, version = _a.version;
                        return id && version;
                    });
                    files = __spreadArray(__spreadArray([], issueFiles, true), actionFiles, true).filter(
                    // @ts-ignore
                    function (_a) {
                        var id = _a.id, version = _a.version;
                        return id && version;
                    });
                    // @ts-ignore
                    return [4 /*yield*/, updateLinkingIdToId(images, files)];
                case 1:
                    // @ts-ignore
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
