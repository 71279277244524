import { FILTER_TYPE, QUERY_TYPE } from '@amzn/austin-core';
// @todo values are being set currently as Yes, No but this will change to yes, no
export var CaseConditionAmcareBypassValue;
(function (CaseConditionAmcareBypassValue) {
    CaseConditionAmcareBypassValue["Yes"] = "Yes";
    CaseConditionAmcareBypassValue["No"] = "No";
    CaseConditionAmcareBypassValue["YES"] = "yes";
    CaseConditionAmcareBypassValue["NO"] = "no";
})(CaseConditionAmcareBypassValue || (CaseConditionAmcareBypassValue = {}));
export var CaseConditionNonWorkRelatedValue;
(function (CaseConditionNonWorkRelatedValue) {
    CaseConditionNonWorkRelatedValue["FALSE"] = "0";
    CaseConditionNonWorkRelatedValue["TRUE"] = "1";
})(CaseConditionNonWorkRelatedValue || (CaseConditionNonWorkRelatedValue = {}));
export var CaseConditionCustomSearchAttributeKey;
(function (CaseConditionCustomSearchAttributeKey) {
    CaseConditionCustomSearchAttributeKey["NON_WORK_RELATED"] = "nonWorkRelated";
    CaseConditionCustomSearchAttributeKey["DAFW_START_DATE"] = "dafwStartDate";
    CaseConditionCustomSearchAttributeKey["DAFW_END_DATE"] = "dafwEndDate";
    CaseConditionCustomSearchAttributeKey["RWA_START_DATE"] = "rwaStartDate";
    CaseConditionCustomSearchAttributeKey["RWA_END_DATE"] = "rwaEndDate";
    CaseConditionCustomSearchAttributeKey["AMCARE_BYPASS"] = "incidentSeekOutsideMedicalPriorReport";
})(CaseConditionCustomSearchAttributeKey || (CaseConditionCustomSearchAttributeKey = {}));
export var FILTER_FIELDS = {
    CASE_NUMBER: 'caseNumber',
    CREATED_AT: 'createdAt',
    CREATOR: 'creator',
    DYNAMIC_SCOPE_NODE_ID: 'dynamicScope.nodeIds.id',
    ID: 'id',
    INCIDENT_DATE_TIME: 'incidentDateTime',
    INCIDENT_GROUP_ID: 'incidentGroupId',
    POTENTIAL_SEVERITY_LEVEL: 'potentialSeverity.level',
    RECORDABLE: 'recordable',
    RISK_LEVEL: 'risk.level',
    SCOPE_NODE_ID: 'scope.nodeIds.id',
    STATUS: 'status',
    SEARCH_ATTRIBUTES_KEY: 'customSearchAttributes.key',
    SEARCH_ATTRIBUTES_VALUE: 'customSearchAttributes.value',
    SEVERITY_LEVEL: 'severity.level',
    IS_PRIMARY_INCIDENT: 'isPrimaryIncident',
    TYPE: 'type'
};
var MATCH = QUERY_TYPE.MATCH;
var MUST = FILTER_TYPE.MUST, MUST_NOT = FILTER_TYPE.MUST_NOT;
export var dafwStartDatePresentFilter = {
    filterType: MUST,
    queryParams: {
        field: FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY,
        queryType: MATCH,
        value: CaseConditionCustomSearchAttributeKey.DAFW_START_DATE
    }
};
export var dafwEndDateNotPresentFilter = {
    filterType: MUST_NOT,
    queryParams: {
        field: FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY,
        queryType: MATCH,
        value: CaseConditionCustomSearchAttributeKey.DAFW_END_DATE
    }
};
export var rwaStartDatePresentFilter = {
    filterType: MUST,
    queryParams: {
        field: FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY,
        queryType: MATCH,
        value: CaseConditionCustomSearchAttributeKey.RWA_START_DATE
    }
};
export var rwaEndDateNotPresentFilter = {
    filterType: MUST_NOT,
    queryParams: {
        field: FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY,
        queryType: MATCH,
        value: CaseConditionCustomSearchAttributeKey.RWA_END_DATE
    }
};
export var GetIncidentFormsOwningType;
(function (GetIncidentFormsOwningType) {
    GetIncidentFormsOwningType["INCIDENT"] = "INCIDENT";
    GetIncidentFormsOwningType["FROI"] = "FROI";
})(GetIncidentFormsOwningType || (GetIncidentFormsOwningType = {}));
export var FilterNames;
(function (FilterNames) {
    FilterNames["caseConditions"] = "caseConditions";
    FilterNames["createdAfter"] = "createdAfter";
    FilterNames["createdBefore"] = "createdBefore";
    FilterNames["creator"] = "creator";
    FilterNames["dateAfter"] = "dateAfter";
    FilterNames["dateBefore"] = "dateBefore";
    FilterNames["externalService"] = "externalService";
    FilterNames["ids"] = "ids";
    FilterNames["incidentDate"] = "incidentDate";
    FilterNames["inGroup"] = "inGroup";
    FilterNames["groupId"] = "groupId";
    FilterNames["medicalStatus"] = "medicalStatus";
    FilterNames["potentialSeverity"] = "potentialSeverity";
    FilterNames["principalBodyPart"] = "principalBodyPart";
    FilterNames["riskLevel"] = "riskLevel";
    FilterNames["search"] = "search";
    FilterNames["searchBy"] = "searchBy";
    FilterNames["severity"] = "severity";
    FilterNames["status"] = "status";
    // Incident types
    FilterNames["employeeType"] = "employeeType";
    FilterNames["eventType"] = "eventType";
    FilterNames["eventSubType"] = "eventSubType";
    FilterNames["type"] = "type";
    // scope fields
    FilterNames["Site"] = "Site";
    FilterNames["SiteType"] = "Site Type";
    FilterNames["Org"] = "Org";
    FilterNames["SubOrg"] = "SubOrg";
    FilterNames["Region"] = "Region";
    FilterNames["Country"] = "Country";
    FilterNames["State"] = "State";
    FilterNames["location"] = "location";
})(FilterNames || (FilterNames = {}));
