import { get } from 'lodash';
import { errorMessageConfig as defaultErrorMessageConfig } from '../../services/messageHandlers';

export const errorCodes = {
    INVALID_INPUT_EXCEPTION: 'InvalidInputException'
};

export const errorTypes = {
    BAD_REQUEST: {
        httpStatusCode: 400,
        errorType: 'BadRequest'
    },
    EXECUTION_TIMEOUT: { errorType: 'ExecutionTimeout' },
    FORBIDDEN: { httpStatusCode: 403, errorType: 'Forbidden' },
    INTERNAL_SERVER_ERROR: {
        httpStatusCode: 500,
        errorType: 'InternalServerError'
    }
};

/**
 * Generic handler for Permission related errors.  Checks the error.message not authorized.
 * @param {Object} error
 * Network api error
 *
 * @returns {FormattedErrorMessage} message
 * Formatted, user friendly error message object
 */
export function defaultPermissionMessage(error) {
    let message;
    const reg = /not authorized/;

    if (reg.test(error?.message)) {
        message = {
            defaultText:
                'User does not have the required permission to perform this action',
            id: 'ehs_incidents_msg_defaultUserNotAuthorizedForAction',
            params: {
                action: get(error, 'path[0]')
            }
        };
    }

    return message;
}

/**
 * Generic handler for Permission related errors.  Checks the error.message not authorized.
 * @param {Object} error
 * Network api error
 *
 * @returns {FormattedErrorMessage} message
 * Formatted, user friendly error message object
 */
export function defaultTimeoutMessage(error) {
    let message;

    if (error?.errorType === errorTypes.EXECUTION_TIMEOUT) {
        const action = get(error, 'path[0]');
        message = {
            defaultText: `${action}: The request took too long to complete.`,
            id: 'ehs_incidents_msg_defaultExecutionTimeoutError',
            params: {
                action
            }
        };
    }

    return message;
}

/**
 * Generic handler for Permission related errors.  Checks the error.message not authorized.
 * @param {Object} error
 * Network api error
 *
 * @returns {FormattedErrorMessage} message
 * Formatted, user friendly error message object
 */
export function defaultErrorMessage(error) {
    let message = {
        defaultText: 'Something went wrong. Please refresh and try again.',
        id: 'ehs_common_msg_unexpectedErrorTryAgain'
    };

    // check if there is a generic permission error
    if (defaultPermissionMessage(error)) {
        message = defaultPermissionMessage(error);
    } else if (defaultTimeoutMessage(error)) {
        message = defaultTimeoutMessage(error);
    }

    return message;
}

function defaultMapErrorResponse(errorResponse) {
    return get(errorResponse, 'errors[0]');
}

function defaultMapOperationName(errorResponse) {
    return get(errorResponse, 'errors[0].path[0]');
}

/**
 * @module
 * useErrorMessages
 *
 * @category
 * Hooks
 *
 * @description
 * This hook provides an abstraction for handling API error messages, and converting them to user friendly text
 *
 * @typedef {Object} FormattedErrorMessage
 * @property {String} defaultMessage - Default error message
 * @property {String} id - Localization id to be passed to translation method
 * @property {Object} params - Object of key/value pairs to be used in translation or other methods
 *
 * @typedef {Object} ErrorMessageConfig
 * @property {Array} errorHandlers - Error messaging mapping functions.  If error matches, fn will return {FormattedErrorMessage}, null or undefined if it doesn't
 * @property {Function} [mapErrorResponse] - Optional helper method. Allows customization of the API error object passed to the Config.errorHandler methods
 * @property {Function} [mapOperationName] - Optional function.  Creates an error operation name, that will be used against the ErrorMessageConfig to determine which error handlers to pass the error
 *
 * @typdef {Object} UseErrorMessagesProperties
 * @property {Function} getConfg - Helper method to return the current ErrorMessageConfig
 * @property {Function} getErrorMessage - Method that will return the formatted error message.
 *
 * @example
 *  import { useErrorMessages } from `./useErrorMessages`;
 *
 *
 *  const errorMappingFunction(errorFromAPI) {
 *     let message;
 *
 *     //
 *     if(errorFromApi.errors[0].errorMessage === 'someErrorToBeHandled') {
 *       message = {
 *         defaultText: error.errors[0].errorMessage, // text that can be displayed or logged
 *         id: 'ehs_errors_msg_localizedMessageId, // localization id to be passed to translation function
 *         params: {
 *           key: value // param object passed to translation function , i.e. `this is a translated string with {key}`
 *         }
 *       }
 *     }
 *
 *     return message;
 *  }
 *
 *  const errorConfig = {
 *    errorHandlers: [errorMappingFunction, anotherErrorMappingFunction]
 *  }
 *
 *  const MyComponent = () => {
 *      const { useErrorMessages } = useErrorMessages(config)
 *
 *      useEffect(() => {
 *        loadData();
 *      }, [])
 *
 *      // Invoked when auto-save timeout has been reached (meaning model is dirty and needs saving).
 *      async function loadData() {
 *        try {
 *          // async api call
 *          await loadDataFromAPI();
 *        } catch(errorFromAPI)
 *          const friendlyMessage = getErrorMessage(error);
 *          const { defaultText, id, params } = friendlyMessage;
 *
 *          let messageToDisplayToUser = defaultText;
 *
 *          // localization id available from handler
 *          if(id) {
 *             // preferred translation method, i.e. useIntl
 *             intl.formatMessage({ id }, params);
 *          }
 *
 *          enqueueMessage({ message, type: 'ERROR' })
 *      }
 *
 *      return (
 *          <Component />
 *      );
 *  }
 *
 *  export default MyComponent;
 *
 * @param {ErrorMessageConfig} config
 * Error Handlers for mapping the raw API error to a friendly messaging format, including localization id and params. Optional helper methods to be applied to API Error before invoking handlers
 *
 *
 * @returns {UseErrorMessagesProperties}
 *
 */
const useErrorMessages = (config = defaultErrorMessageConfig) => {
    const {
        mapErrorResponse = defaultMapErrorResponse,
        mapOperationName = defaultMapOperationName
    } = config;

    /**
     *
     * @returns The current config
     */
    function getConfig() {
        return config;
    }

    /**
     * Passed error response to message handlers, returns the first message object defined.  Handlers should be defined in the config
     * in the preferred order of display
     *
     * @todo provide a mechanism to pass context from the calling function, which can be used to make ids to readable text
     *
     * @param {Object} errorResponse
     * @returns {FormattedErrorMessage} message
     */
    function getErrorMessage(errorResponse) {
        let message;

        try {
            const error = mapErrorResponse(errorResponse);
            const operationName = mapOperationName(errorResponse);

            message = get(config?.errorHandlers, operationName, [])
                .map((fn) => fn(error))
                .find((msg) => Boolean(msg));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error mapping errorMessage', err);
        }

        return message ?? defaultErrorMessage(errorResponse);
    }

    return {
        getConfig,
        getErrorMessage
    };
};

export { useErrorMessages };
