var EU_COUNTRIES = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE' // 'Sweden'
];
var EUROPEAN_COUNTRIES = EU_COUNTRIES.concat([
    'IS',
    'LI',
    'NO',
    'AL',
    'AN',
    'CH',
    'UK',
    'GB' // 'United Kingdom' (Great Britain), including for broader coverage
]);
var NA_COUNTRIES = [
    'AI',
    'AG',
    'AW',
    'BB',
    'BZ',
    'BM',
    'BQ',
    'VG',
    'CA',
    'KY',
    'CR',
    'CU',
    'CW',
    'DM',
    'DO',
    'SV',
    'GL',
    'GD',
    'GP',
    'GT',
    'HT',
    'HN',
    'JM',
    'MQ',
    'MX',
    'MS',
    'AN',
    'NI',
    'PA',
    'PR',
    'BL',
    'KN',
    'LC',
    'MF',
    'PM',
    'VC',
    'SX',
    'BS',
    'TT',
    'TC',
    'US',
    'VI' // 'US Virgin Islands'
];
var ASIAN_COUNTRIES = [
    'AF',
    'AM',
    'AZ',
    'BH',
    'BD',
    'BT',
    'IO',
    'BN',
    'KH',
    'CN',
    'CC',
    'HK',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IL',
    'JP',
    'JO',
    'KZ',
    'KW',
    'KG',
    'LA',
    'LB',
    'MO',
    'MY',
    'MV',
    'MN',
    'MM',
    'NP',
    'KP',
    'OM',
    'PK',
    'PS',
    'PH',
    'QA',
    'SA',
    'SG',
    'KR',
    'LK',
    'SY',
    'TW',
    'TJ',
    'TH',
    'TR',
    'TM',
    'AE',
    'UZ',
    'VN',
    'YE' // 'Yemen'
];
var OCEANIA_COUNTRIES = [
    'AS',
    'AU',
    'CX',
    'CK',
    'FJ',
    'PF',
    'GU',
    'KI',
    'MH',
    'FM',
    'NR',
    'NC',
    'NZ',
    'NU',
    'NF',
    'MP',
    'PW',
    'PG',
    'PN',
    'WS',
    'SB',
    'TL',
    'TK',
    'TO',
    'TV',
    'VU',
    'WF' // 'Wallis And Futuna'
];
var FE_COUNTRIES = ASIAN_COUNTRIES.concat(OCEANIA_COUNTRIES);
/**
 * Check if a country is an EU country
 *
 * @return {Boolean}
 * @param country
 */
export var isEUCountry = function (country) {
    return EUROPEAN_COUNTRIES.includes(country);
};
/**
 * Check if a country is a NA country
 *
 * @return {Boolean}
 * @param country
 */
export var isNACountry = function (country) {
    return NA_COUNTRIES.includes(country);
};
/**
 * Check if a country is a FE country
 *
 * @return {Boolean}
 * @param country
 */
export var isFECountry = function (country) {
    return FE_COUNTRIES.includes(country);
};
// TODO: migrate to use AustinCore when BE returns region https://issues.amazon.com/issues/AUSTIN-33794
export var getSiteRegion = function (country) {
    if (country) {
        if (isEUCountry(country)) {
            return 'EU';
        }
        if (isNACountry(country)) {
            return 'NA';
        }
        if (isFECountry(country)) {
            return 'FE';
        }
    }
    return undefined;
};
