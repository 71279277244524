var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { get } from 'lodash';
/**
 * Error message handlers
 *
 * @typedef {Object} FormattedErrorMessage
 * @property {String} defaultMessage - Default error message
 * @property {String} id - Localization id to be passed to translation method
 * @property {Object} params - Object of key/value pairs to be used in translation or other methods
 */
/**
 * IncidentService
 * Permission check error from Incidents service
 * @param {Object} error
 * @returns {FormattedErrorMessage} ErrorMessage object
 */
export function incidentNotAuthorizedErrorCheck(error) {
    var message;
    var reg = /User (\w+) not authorized to access incident/;
    if (reg.test(error === null || error === void 0 ? void 0 : error.message)) {
        var _a = error.message.match(reg), userAlias = _a[1];
        message = {
            defaultText: 'User does not have the required permission to perform this action.',
            id: 'ehs_incidents_msg_incidentUserNotAuthorized',
            params: {
                userAlias: userAlias
            }
        };
    }
    return message;
}
/**
 * IncidentService
 * Incident not found in ES, generally returns as List not found. error.
 * @param {Object} error
 * @returns {FormattedErrorMessage} ErrorMessage object
 */
export function incidentNotFoundCheck(error) {
    var message;
    if (['List is empty.'].includes(get(error, 'message')) ||
        get(error, 'errorInfo.cause.errorCode') === 'EntityNotFoundException') {
        message = {
            defaultText: 'Entity with specified ID could not be found.',
            id: 'ehs_incidents_msg_incidentNotFoundError'
        };
    }
    return message;
}
/**
 * IncidentService
 * Incident not found in ES, generally returns as List not found. error.
 * @param {Object} error
 * @returns {FormattedErrorMessage} ErrorMessage object
 */
export function editActionPlanCheck(error) {
    var message;
    var reg = /Cannot delete an action item after it is finalized/;
    if (reg.test(error === null || error === void 0 ? void 0 : error.message)) {
        message = {
            defaultText: error.message,
            id: 'ehs_incidents_msg_openActionsCannotBeDeletedError'
        };
    }
    return message;
}
/**
 * Forms Service
 * Form submission with missing required question answers, question answers that are not applicable, or Form is not able to be updated.
 * @param {Object} error
 * @returns {FormattedErrorMessage} ErrorMessage object
 */
export function formInvalidFormSubmissionMessage(error) {
    var _a, _b;
    // Error check for missing required question answers
    var unansweredQuestionsReg = /Every applicable question \[.+\] must be answered/;
    // Error check for answers submitted, that should not be included
    var invalidAnswersForFormReg = /Not applicable follow-up questions \[.+\] must be un-answered/;
    // Checks for form that cannot be updated due to FormStatus
    var formCantBeUpdatedReg = /with status COMPLETED cannot be updated/;
    var questionIdReg = /(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/g;
    var message;
    if (unansweredQuestionsReg.test(error === null || error === void 0 ? void 0 : error.message)) {
        var questionIds = ((_a = error.message.match(questionIdReg)) !== null && _a !== void 0 ? _a : []).join(', ');
        message = {
            defaultText: 'Every applicable question must be answered.',
            id: 'ehs_incidents_msg_requiredQuestionsMustBeAnswered',
            params: {
                questionIds: questionIds
            }
        };
    }
    else if (invalidAnswersForFormReg.test(error === null || error === void 0 ? void 0 : error.message)) {
        var questionIds = ((_b = error.message.match(questionIdReg)) !== null && _b !== void 0 ? _b : []).join(', ');
        message = {
            defaultText: 'Form has answers for questions that are not applicable.  Please check your answers and try again.',
            id: 'ehs_incidents_msg_notApplicableQuestionAnswersSubmitted',
            params: {
                questionIds: questionIds
            }
        };
    }
    else if (formCantBeUpdatedReg.test(error === null || error === void 0 ? void 0 : error.message)) {
        message = {
            defaultText: error === null || error === void 0 ? void 0 : error.message,
            id: 'ehs_incidents_msg_formCannotBeUpdatedError'
        };
    }
    return message;
}
// Error handlers for IncidentService
export var IncidentsErrorMessageHandlers = {
    createInitialReport: [],
    getIncident: [incidentNotAuthorizedErrorCheck, incidentNotFoundCheck],
    getIncidentSummary: [incidentNotAuthorizedErrorCheck],
    submitForm: [formInvalidFormSubmissionMessage]
};
// Error handlers for IncidentService
export var rcaErrorMessageHandlers = {
    failedActions: [editActionPlanCheck]
};
// Default message handlers for all services
export var errorMessageConfig = {
    errorHandlers: __assign(__assign({}, IncidentsErrorMessageHandlers), rcaErrorMessageHandlers)
};
