import { isBefore } from 'date-fns';

export const ASCENDING = 'ASC';
export const DESCENDING = 'DESC';
export const LABEL_ASC = 'LABEL_ASC';
export const LABEL_DESC = 'LABEL_DESC';

/**
 * Default comparator for ascending sort; Uses String.prototype.localeCompare
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 *
 * @param {String|Number} strA
 * @param {String|Number} strB
 * @param {String} [locale=undefined]
 * Optional locale to use in string comparison
 *
 * @param {Object} [options={ numeric: true }]
 * Optional options to pass to sort function.  Defaults to use { numeric: true } for String.prototype.localeCompare method.
 * This allows expected sorting of number strings (10, 20 etc)
 *
 * @returns {Int} - Value to sort in ascending order by comparing strA > strB
 */
export function sortStringAscending(
    strA,
    strB,
    locale,
    sortOptions = { numeric: true, sensitivity: 'base' }
) {
    return `${strA}`.localeCompare(`${strB}`, locale, sortOptions);
}

/**
 * Default comparator for descending sort; Uses String.prototype.localeCompare
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
 *
 * @param {String|Number} strA
 * @param {String|Number} strB
 * @param {String} [locale=undefined]
 * Optional locale to use in string comparison
 *
 * @param {Object} [sortOptions={ numeric: true }]
 * Optional options to pass to sort function.  Defaults to use { numeric: true } for String.prototype.localeCompare method.
 * This allows expected sorting of number strings (10, 20 etc)
 *
 * @returns {Int} - Value to sort in descending order by comparing strA > strB
 */
export function sortStringDescending(
    strA,
    strB,
    locale,
    sortOptions = { numeric: true, sensitivity: 'base' }
) {
    return `${strB}`.localeCompare(`${strA}`, locale, sortOptions);
}

/**
 * Composition for sorting by object values;
 *
 * @param {String} [dir='ASC']
 * Enum value for sort direction; Defaults to ASC.
 *
 * @param {String} [field='text']
 * The field name to be used in the sort comparison.  This expects the items passed to the returned sort function
 * will be an object with the field name as a property.
 *
 * @returns {Function}
 * Composed sort function from passed parameters
 */
export function sortOptionByField(
    dir = ASCENDING,
    field = 'text',
    locale,
    sortOptions
) {
    const sortFn =
        dir === ASCENDING ? sortStringAscending : sortStringDescending;
    return function sortOptionByFieldFn(optionA, optionB) {
        return sortFn(optionA[field], optionB[field], locale, sortOptions);
    };
}
export const SORT_TYPES = {
    [LABEL_ASC]: (locale, options) =>
        sortOptionByField(ASCENDING, 'text', locale, options),
    [LABEL_DESC]: (locale, options) =>
        sortOptionByField(DESCENDING, 'text', locale, options)
};

/**
 * Helper for getting sort functions for Question answer options
 *
 * @param {String} sortType
 * Sort method to use from question.answerTemplate
 *
 * @param {String} [locale]
 * Language/Locale to be passed to sort comparator;  Will default to en if none are passed
 *
 * @param {Object} [options]
 * Optional object to be passed to sort comparator
 *
 * @returns {Function}
 * The sort method that will be used in Array.protoype.sort
 *
 */
export function getAnswerOptionSort(sortType, locale, options) {
    const fn = SORT_TYPES[sortType];
    return fn ? fn(locale, options) : null;
}

/**
 * Helper to sort incidents within a group by primary, oldest created -> newest created
 * @param a
 * @param b
 * @returns {number}
 */
export function sortGroupedIncidentsByPrimaryThenAscendingCreatedAt(a, b) {
    if (a?.isPrimaryIncident === b?.isPrimaryIncident) {
        // Sorted by ascending createdAt
        return isBefore(new Date(b?.createdAt), new Date(a?.createdAt))
            ? 1
            : -1;
    }

    // Primary first
    return b?.isPrimaryIncident - a?.isPrimaryIncident;
}
