/* istanbul ignore file */
import { formTemplates, FORM_TYPES } from '../templates/forms/templates';
import { AUSTIN_TENANTS } from '../../common/environment';

const { AWS, WHS } = AUSTIN_TENANTS;
export function getIncidentCreateForm(params = {}) {
    const { usePAPIIntegration = false, tenant = WHS } = params;
    let formType = usePAPIIntegration
        ? FORM_TYPES.CREATE_INCIDENT_PAPI
        : FORM_TYPES.CREATE_INCIDENT;
    if (tenant === AWS) {
        formType = FORM_TYPES.CREATE_INCIDENT_AWS;
    }

    return formTemplates.find(({ type }) => type === formType);
}

export function getNonAmazonianCreateForm(contractorType) {
    const isValidFormType = !!FORM_TYPES[contractorType];
    return {
        // prettier-ignore
        nonAmazonianCreateForm: formTemplates.find(({ type }) => (isValidFormType ? type === contractorType : type === FORM_TYPES.CREATE_CONTRACTOR)
        )
    };
}
