/* istanbul ignore file */
/**
 * @todo This file is copied from austin-core
 * This can be removed when we are properly migrated to use common components/hooks for file service v2.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { executeRequest } from '@amzn/austin-core';
import { FILE_TYPE } from '../types';
import { getMimeType } from './helpers';
var getAttachmentsMetadataQuery = "query getAttachmentsMetadata(\n    $input: GetAttachmentsMetadataInput!\n) {\n    result: getAttachmentsMetadata(\n        input: $input\n    ) {\n        fileStorageMetadata {\n            createdAt,\n            extension,\n            filename,\n            id,\n            uploadedBy\n        },\n        imageMetadata {\n            createdAt,\n            extension,\n            filename,\n            id,\n            uploadedBy\n        }\n    }\n}";
var getAttachmentSignedUrlsQuery = "query getAttachmentSignedUrls(\n    $input: GetAttachmentsSignedUrlsInput!\n) {\n    result: getAttachmentSignedUrls(\n        input: $input\n    ) {\n        results {\n            errorMessage,\n            mimeType,\n            requestedId,\n            urls {\n                type,\n                url\n            }\n        }\n    }\n}";
/**
 * This async function takes a list of ids and performs parallel query operations to get both signed URL's
 * and attachment metadata. As there is no single query to get both at the moment, we'll have to merge the
 * results of both and normalize the data structure before updating internal `files` state.
 */
export function getAttachments(ids, requestOptions) {
    return __awaiter(this, void 0, void 0, function () {
        var savedAttachments, getAttachmentSignedUrlsInput, getAttachmentMetadataInput, requests, _a, firstResult, secondResult, attachments, _b, fileStorageMetadata_1, imageMetadata_1, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    savedAttachments = [];
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    getAttachmentSignedUrlsInput = {
                        input: { requests: ids }
                    };
                    getAttachmentMetadataInput = {
                        input: { attachmentIds: ids }
                    };
                    requests = [
                        executeRequest(getAttachmentSignedUrlsQuery, getAttachmentSignedUrlsInput, requestOptions),
                        executeRequest(getAttachmentsMetadataQuery, getAttachmentMetadataInput, requestOptions)
                    ];
                    return [4 /*yield*/, Promise.all(requests)];
                case 2:
                    _a = _c.sent(), firstResult = _a[0], secondResult = _a[1];
                    attachments = firstResult.result.results;
                    _b = secondResult.result, fileStorageMetadata_1 = _b.fileStorageMetadata, imageMetadata_1 = _b.imageMetadata;
                    /**
                     * @note
                     * This basically merges the results of the two queries above to produce a list of
                     * normalized FileAttachment types that the UI expects.
                     *
                     * @todo
                     * Create a ticket to propose a single query that returns a type that the UI can
                     * work with to eliminate the complexity below.
                     */
                    savedAttachments = attachments.map(function (_a) {
                        var _b, _c;
                        var mimeType = _a.mimeType, requestedId = _a.requestedId, urls = _a.urls;
                        var createdAt = '';
                        var name = '';
                        var signedUrl = '';
                        var uploadedBy = '';
                        /**
                         * We don't know the type, so have to query fileStorageMetadata first and then
                         * imageMetadata if no match is found to determine the file name to display.
                         * Similarly, we get the first available URL if it's a non-image file.
                         */
                        var fileAttachment = fileStorageMetadata_1.find(function (_a) {
                            var id = _a.id;
                            return id === requestedId;
                        });
                        if (fileAttachment) {
                            createdAt = fileAttachment.createdAt;
                            name = fileAttachment.filename;
                            uploadedBy = fileAttachment.uploadedBy;
                            signedUrl =
                                ((_b = urls.find(function (url) { return url.type === FILE_TYPE.GENERAL_STORAGE; })) === null || _b === void 0 ? void 0 : _b.url) || '';
                        }
                        else {
                            var imageAttachment = imageMetadata_1.find(function (_a) {
                                var id = _a.id;
                                return id === requestedId;
                            });
                            if (imageAttachment) {
                                createdAt = imageAttachment.createdAt;
                                name = imageAttachment.filename || name;
                                uploadedBy = imageAttachment.uploadedBy;
                                signedUrl =
                                    ((_c = urls.find(function (url) { return url.type === FILE_TYPE.MAX_IMAGE; })) === null || _c === void 0 ? void 0 : _c.url) || '';
                            }
                        }
                        return {
                            createdAt: createdAt,
                            id: requestedId,
                            name: name || requestedId,
                            progress: 100,
                            signedUrl: signedUrl,
                            type: mimeType || getMimeType(name),
                            uploadedBy: uploadedBy
                        };
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    // eslint-disable-next-line no-console
                    console.error(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/, savedAttachments];
            }
        });
    });
}
