var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* istanbul ignore file */
import { startOfDay, endOfDay } from 'date-fns';
import { get, isString } from 'lodash';
import { COMPOSE_OPERATOR, FILTER_TYPE, QUERY_TYPE } from '@amzn/austin-core';
import { FIELD_TYPES } from '../components/fields/fieldTypes';
import { ENTITY_TYPE, INCIDENT_STATUS, INCIDENT_MEDICAL_STATUS, INCIDENT_TYPES, INCIDENT_CASE_CONDITION, EXTERNAL_INCIDENT_TYPES } from '../common/constants';
import { LABEL_ASC, getAnswerOptionSort } from '../common/helpers/index';
import { getAbsoluteDateValueFromRelativeDays } from '../common/helpers/dates';
import { CaseConditionAmcareBypassValue, CaseConditionCustomSearchAttributeKey, CaseConditionNonWorkRelatedValue, dafwEndDateNotPresentFilter, dafwStartDatePresentFilter, rwaEndDateNotPresentFilter, rwaStartDatePresentFilter, FILTER_FIELDS } from './types';
/**
 * Question relationships (parent/child questions) are stored separately from the questions in the form
 * This helper creates a map of question Ids that are optionally rendered based on another question value
 * This map is applied to the questions and evaluated when the question is rendered
 * @param {Array} questionRelationships
 *
 * @returns {Object} conditionalQuestions
 * Object containing the ids of conditional questions, and the { field, value} of another question to evalute against
 *
 */
export function createQuestionConditions(questionRelationships, questions) {
    if (questionRelationships === void 0) { questionRelationships = []; }
    if (questions === void 0) { questions = []; }
    var optionalQuestions = {};
    var conditionalQuestions = [];
    var questionsWithFollowUps = {};
    var questionsByExternalId = questions.reduce(function (map, q) {
        var _a;
        return (__assign(__assign({}, map), (_a = {}, _a[q.externalId] = q, _a)));
    }, {});
    questionRelationships.forEach(function (_a) {
        var questionId = _a.questionId, _b = _a.optional, optional = _b === void 0 ? false : _b, _c = _a.followUpQuestions, followUpQuestions = _c === void 0 ? [] : _c;
        // check if question is optional
        if (optional) {
            optionalQuestions[questionId] = true;
        }
        questionsWithFollowUps[questionId] = followUpQuestions;
        followUpQuestions.forEach(function (_a) {
            var answerId = _a.answerId, followUpQuestionIds = _a.followUpQuestionIds;
            followUpQuestionIds.forEach(function (followUpQuestionId) {
                conditionalQuestions.push({
                    field: questionId,
                    childField: followUpQuestionId,
                    conditionType: 'OR',
                    value: answerId
                });
            });
        });
    });
    return {
        optionalQuestions: optionalQuestions,
        conditionalQuestions: conditionalQuestions,
        questionsByExternalId: questionsByExternalId,
        questionsWithFollowUps: questionsWithFollowUps
    };
}
/**
 * Helper method to map the nested API response to match component props
 * Parses answerTemplate JSON
 * @param {Object} question
 *
 * @param {Object} [questionConditions = {}]
 *
 * @param formLocale
 * @param formQuestions
 * Optional form locale
 *
 * @returns {Object} question
 * Mapped question object
 */
export function normalizeQuestions(question, questionConditions, formLocale, formQuestions) {
    var _a, _b, _c, _d, _e, _f;
    if (formQuestions === void 0) { formQuestions = []; }
    var _g = questionConditions !== null && questionConditions !== void 0 ? questionConditions : {}, _h = _g.conditionalQuestions, conditionalQuestions = _h === void 0 ? [] : _h, _j = _g.optionalQuestions, optionalQuestions = _j === void 0 ? {} : _j, _k = _g.questionsByExternalId, questionsByExternalId = _k === void 0 ? {} : _k, _l = _g.questionsWithFollowUps, questionsWithFollowUps = _l === void 0 ? {} : _l;
    var answerLabels = get(question, 'questionText.answerLabels', []);
    var answerTemplate = {};
    var euAnswerTemplate = {};
    var attributes = [];
    var conditionType = 'OR';
    var conditions = conditionalQuestions.filter(function (_a) {
        var childField = _a.childField;
        return childField === question.id;
    });
    try {
        answerTemplate = JSON.parse(question.answerTemplate);
        // EntityAnswerTemplate sets its render type as answerTemplate.entityType
        var entityType = get(answerTemplate, 'entityType', '');
        if (entityType && answerTemplate.renderAs === FIELD_TYPES.TEXT_FIELD) {
            answerTemplate.renderAs = answerTemplate.entityType;
        }
        // Site Config / Risk select require special handling to set answer options
        if ([ENTITY_TYPE.SITE_CONFIG_LOCATION].includes(entityType)) {
            answerTemplate.renderAs = FIELD_TYPES.SITE_CONFIG_ATTRIBUTE_SELECT;
        }
        if ([ENTITY_TYPE.SITE_CONFIG_PROCESS_PATH].includes(entityType)) {
            answerTemplate.renderAs = FIELD_TYPES.PROCESS_PATH_SELECT;
        }
        if ([
            ENTITY_TYPE.RISK_ASSESSMENT_GROUP,
            ENTITY_TYPE.RISK_ASSESSMENT_CATEGORY,
            ENTITY_TYPE.RISK_ASSESSMENT_HAZARD
        ].includes(entityType)) {
            answerTemplate.renderAs = FIELD_TYPES.RISK_ASSESSMENT_SELECT;
        }
        var metadata = get(question, 'metadata', []);
        // extended conditions
        // Forms/Question bank do not support multiple AND conditions, they need to be defined on metadata and overridden
        var extendedConditionJson = (_a = metadata.find(function (_a) {
            var key = _a.key;
            return key === 'extendedConditions';
        })) === null || _a === void 0 ? void 0 : _a.value;
        if (extendedConditionJson) {
            var extendedConditions = JSON.parse(extendedConditionJson);
            conditionType = extendedConditions.conditionType;
            var parentFields = get(extendedConditions, 'parentFields', []);
            parentFields.forEach(function (field) {
                if (questionsByExternalId[field.externalId]) {
                    conditions.push({
                        childField: question.id,
                        field: questionsByExternalId[field.externalId].id,
                        value: field.value,
                        conditionType: conditionType
                    });
                }
            });
        }
        // @todo, temp to render range/NonAmazonianField from metadata
        var extendedAnswerTemplateJson = (_b = get(question, 'metadata', []).find(function (_a) {
            var key = _a.key;
            return key === 'extendedAnswerTemplate';
        })) === null || _b === void 0 ? void 0 : _b.value;
        if (extendedAnswerTemplateJson) {
            var extendedAnswerTemplate = JSON.parse(extendedAnswerTemplateJson);
            answerTemplate = __assign(__assign({}, answerTemplate), extendedAnswerTemplate);
            // check if the field is populated by be data and should be read only
            var _m = extendedAnswerTemplate.uneditableOnceAnswered, uneditableOnceAnswered = _m === void 0 ? false : _m, _o = extendedAnswerTemplate.formatValueAs, formatValueAsDuration = _o === void 0 ? false : _o;
            if (uneditableOnceAnswered) {
                try {
                    var disabled = JSON.parse(uneditableOnceAnswered);
                    attributes.push({ key: 'disabled', value: disabled });
                }
                catch (err) {
                    // eslint-disable-next-line no-console
                    console.error('Error parsing question answer template', err);
                }
            }
            // check if there are special considerations for date/time formatting
            // @temp answerTemplate value is incorrect
            // @todo temp implementation, need to refactor how localization for answer values is done for different types
            if (formatValueAsDuration) {
                answerTemplate.formatValueAs = 'duration';
                answerTemplate.renderAs = FIELD_TYPES.TENURE;
            }
        }
        // Site regional template e.g. EU week picker. If more sites needed, parse here
        var euAnswerTemplateJson = (_c = get(question, 'metadata', []).find(function (_a) {
            var key = _a.key;
            return key === 'euAnswerTemplate';
        })) === null || _c === void 0 ? void 0 : _c.value;
        if (euAnswerTemplateJson) {
            euAnswerTemplate = JSON.parse(euAnswerTemplateJson);
        }
        // @todo: remove when READ_ONLY question type is supported
        // QB does not support a READ_ONLY field type, but this type is required
        // for certain form flows;  Checking for category name readOnly , which will be set by
        // BE for question types that need to be displayed this way
        if (((_d = question === null || question === void 0 ? void 0 : question.category) === null || _d === void 0 ? void 0 : _d.name) === 'readOnly') {
            answerTemplate.renderAs = FIELD_TYPES.READ_ONLY;
        }
        var answerOptions = get(answerTemplate, 'answerOptions');
        if (Array.isArray(answerOptions)) {
            var answerLabelsMap_1 = answerLabels.reduce(function (acc, _a) {
                var _b;
                var key = _a.key, value = _a.value;
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = value, _b)));
            }, {});
            // @todo Legacy forms have the option text in `answerOptions` while current
            // forms have them in `answerLabels`. When all forms are updated, `option.text`
            // will no longer have data.
            answerTemplate.answerOptions = answerOptions.map(function (option) { return (__assign(__assign({}, option), { text: option.text || answerLabelsMap_1[option.answerId] })); });
            // Selects can support sorting of answerOptions
            if (answerTemplate.renderAs === FIELD_TYPES.SELECT) {
                var sortBy = get(answerTemplate, 'sortBy', LABEL_ASC);
                var sortOptions = get(answerTemplate, 'sortOpions');
                // Form locale is formatted similar to en-US, string comparison expects en
                var locale = void 0;
                if (formLocale) {
                    locale = formLocale.split('-')[0];
                }
                var sortFn = getAnswerOptionSort(sortBy, locale, sortOptions);
                if (sortFn) {
                    // @ts-ignore
                    answerTemplate.answerOptions.sort(sortFn);
                }
            }
        }
        // check for additional rendering overrides
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error parsing answer template', err, {
            question: question
        });
    }
    // Make form questions into a map to access answerChoices by id
    var formQuestionMap = formQuestions.reduce(function (previousValue, currentValue) {
        var _a;
        return (__assign(__assign({}, previousValue), (_a = {}, _a[currentValue.id] = currentValue, _a)));
    }, {});
    return {
        id: question.id,
        name: get(question, 'externalId', question.id),
        text: get(question, 'questionText.text', ''),
        guidance: get(question, 'questionText.guidance.text', ''),
        answerGuidance: get(question, 'questionText.answerGuidance'),
        answerChoices: get(formQuestionMap, "".concat(question.id, ".answerChoices")),
        answerLabels: answerLabels,
        answerTemplate: answerTemplate,
        attributes: attributes,
        optional: get(optionalQuestions, question.id, false),
        conditionType: conditionType,
        conditions: conditions,
        followUpQuestions: get(questionsWithFollowUps, question.id),
        version: question.version,
        questionData: question,
        hidden: get(question, 'hidden', false),
        override: {
            eu: {
                text: (_e = get(question, 'questionText.metadata', []).find(function (_a) {
                    var key = _a.key;
                    return key === 'euText';
                })) === null || _e === void 0 ? void 0 : _e.value,
                guidance: (_f = get(question, 'questionText.metadata', []).find(function (_a) {
                    var key = _a.key;
                    return key === 'euGuidance';
                })) === null || _f === void 0 ? void 0 : _f.value,
                answerTemplate: euAnswerTemplate
            }
        }
    };
}
/**
 * Parse answer values if they are a JSON string
 * @param {String} answer
 * Answer value
 *
 * @returns {Unknown}
 * Parsed value
 */
function normalizeAnswerValue(answer) {
    var objReg = /^\{.*\}$/;
    var arrReg = /^\[.*\]$/;
    var parsed = answer;
    try {
        if (isString(answer)) {
            if (answer.match(objReg) ||
                answer.match(arrReg) ||
                ['true', 'false'].includes(answer.toLowerCase())) {
                parsed = JSON.parse(answer);
            }
        }
    }
    catch (_a) {
        // ignore
    }
    return parsed;
}
/**
 * Helper method to map the nested API response to match component props
 * Parses answerTemplate JSON
 * @param {Object} form
 *
 * @returns {Object} form
 * Mapped form object
 */
export function normalizeForm(form) {
    var questionRelationships = get(form, 'questionSet.questionSet.questionRelationships', []);
    var questions = get(form, 'questions.questions', []);
    var questionConditions = createQuestionConditions(questionRelationships, questions);
    var answers = get(form, 'answers', []).map(function (_a) {
        var _b;
        var answerList = _a.answerList, answer = __rest(_a, ["answerList"]);
        return (__assign(__assign({}, answer), { answerList: answerList && Array.isArray(answerList)
                ? answerList.map(normalizeAnswerValue)
                : answerList, version: (_b = get(form, 'questions.questions', []).find(function (_a) {
                var id = _a.id;
                return id === answer.questionId;
            })) === null || _b === void 0 ? void 0 : _b.version }));
    });
    function normalizeQuestionsWithConditions(question) {
        return normalizeQuestions(question, questionConditions, form.locale, form.formQuestions);
    }
    return __assign(__assign({}, form), { guidance: get(form, 'formTemplate.formTemplate.formGuidance.text', ''), questions: questions.map(normalizeQuestionsWithConditions), answers: answers, questionRelationships: questionRelationships });
}
export function verifyCaseNumber(input) {
    if (input === void 0) { input = ''; }
    var sectionOneRegEx = /[0-9-A-Z]/g;
    var sectionTwoRegEx = /^[0-9a-z-A-Z-]{12}$/;
    var sectionOneString = '';
    var sectionTwoString = '';
    var remainingCharacters = 0;
    var isCaseNumber = false;
    for (var i = 0; i < input.length; i += 1) {
        if (input[i] === '-') {
            remainingCharacters = input.slice(i, input.length).length;
            if (remainingCharacters === 12) {
                sectionOneString = input.slice(0, i);
                sectionTwoString = input.slice(i, input.length);
            }
        }
    }
    if (sectionOneString.match(sectionOneRegEx) &&
        sectionTwoString.match(sectionTwoRegEx)) {
        isCaseNumber = true;
    }
    return isCaseNumber;
}
export function verifyUserAlias(input) {
    if (input === void 0) { input = ''; }
    var aliasRegex = /^@([A-Za-z]*)$/;
    var aliasMatch = input.match(aliasRegex);
    return aliasMatch ? aliasMatch[1] : null;
}
export function verifyIncidentId(input) {
    if (input === void 0) { input = ''; }
    var incidentIdRegEx = /^[0-9a-z-]{36}$/;
    var isIncidentId = false;
    if (input.match(incidentIdRegEx)) {
        isIncidentId = true;
    }
    return isIncidentId;
}
export function verifyIncidentGroupId(input) {
    if (input === void 0) { input = ''; }
    // e.g. an UUID, 8724e3d2-cf69-4ebc-880b-50b3f1bbf081
    var connectedIncidentGroupIdRegEx = /^[0-9a-z-]{36}$/g;
    // e.g. DEMO1-24-GROUP-201
    var multiPersonIncidentGroupIdRegEx = /^[0-9a-zA-Z]{1,}-[0-9]{2}-GROUP-[0-9]{1,}$/g;
    var isIncidentGroupId = false;
    if (input.match(connectedIncidentGroupIdRegEx) ||
        input.match(multiPersonIncidentGroupIdRegEx)) {
        isIncidentGroupId = true;
    }
    return isIncidentGroupId;
}
export function verifyGensuiteId(input) {
    if (input === void 0) { input = ''; }
    var gensuiteIdRegEx = /[0-9a-z_]/g;
    var underscoreCount = 0;
    for (var i = 0; i < input.length; i += 1) {
        if (input[i] === '_') {
            underscoreCount += 1;
        }
    }
    var isGensuiteId = Boolean(Array.from(input.matchAll(gensuiteIdRegEx)).length) &&
        underscoreCount === 3;
    return isGensuiteId;
}
/**
 * @todo Date/Time helpers moved to /common/helpers/dates.js
 */
export function getDateWithTimezoneOffset(date) {
    var unconvertedDate = new Date(date);
    // use the time zone offset converted to milliseconds to handle UTC conversion
    return new Date(unconvertedDate.getTime() + unconvertedDate.getTimezoneOffset() * 60000);
}
export function getDateStringEndOfDay(date) {
    return endOfDay(new Date(getDateWithTimezoneOffset(date))).toISOString();
}
export function getDateStringStartOfDay(date) {
    return startOfDay(new Date(getDateWithTimezoneOffset(date))).toISOString();
}
export var SEARCH_FIELD_TYPES = {
    ANY: 'any',
    ALIAS: 'alias',
    CASE_NUMBER: FILTER_FIELDS.CASE_NUMBER,
    DESCRIPTION: 'description',
    EXTERNAL_ID: 'externalId',
    INCIDENT_ID: 'incidentId',
    INCIDENT_GROUP_ID: 'incidentGroupId'
};
/**
 * Gets the search query type for metrics events
 * checks search value against alias, incident id, case number, and gensuite id regex
 * @param {String} searchQuery
 * @returns {String}
 */
export function getSearchType(searchQuery) {
    if (searchQuery === void 0) { searchQuery = ''; }
    var ANY = SEARCH_FIELD_TYPES.ANY, ALIAS = SEARCH_FIELD_TYPES.ALIAS, CASE_NUMBER = SEARCH_FIELD_TYPES.CASE_NUMBER, EXTERNAL_ID = SEARCH_FIELD_TYPES.EXTERNAL_ID, INCIDENT_ID = SEARCH_FIELD_TYPES.INCIDENT_ID, INCIDENT_GROUP_ID = SEARCH_FIELD_TYPES.INCIDENT_GROUP_ID;
    var searchType = ANY;
    try {
        if (verifyUserAlias(searchQuery)) {
            searchType = ALIAS;
        }
        else if (verifyIncidentId(searchQuery)) {
            searchType = INCIDENT_ID;
        }
        else if (verifyCaseNumber(searchQuery)) {
            searchType = CASE_NUMBER;
        }
        else if (verifyGensuiteId(searchQuery)) {
            searchType = EXTERNAL_ID;
        }
        else if (verifyIncidentGroupId(searchQuery)) {
            searchType = INCIDENT_GROUP_ID;
        }
    }
    catch (err) {
        // swallow error
    }
    return searchType;
}
// Default pagination value for Incident search
export var PAGE_SIZE = 50;
export function getFilterValue(value) {
    var filterValue = value;
    if (typeof value === 'string') {
        filterValue = [value.trim()];
    }
    return filterValue;
}
/**
 * Helper method to parse searchQuery for specific properties
 *
 * @params {String} searchQuery
 * String that contains the query entered in the search picked up by the event handler
 *
 * @returns {Object} QueryFilters
 * Filters to be passed to search Appsync request
 */
export function parseSearchQuery(searchValue, searchBy, queryFilters, composeFilters) {
    var _a;
    if (searchBy === void 0) { searchBy = SEARCH_FIELD_TYPES.ANY; }
    var MUST = FILTER_TYPE.MUST;
    var FUZZY_MATCH = QUERY_TYPE.FUZZY_MATCH, MATCH = QUERY_TYPE.MATCH, GREATER_THAN_EQUAL = QUERY_TYPE.GREATER_THAN_EQUAL;
    var CASE_NUMBER = FILTER_FIELDS.CASE_NUMBER, ID = FILTER_FIELDS.ID, SEARCH_ATTRIBUTES_KEY = FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY, SEARCH_ATTRIBUTES_VALUE = FILTER_FIELDS.SEARCH_ATTRIBUTES_VALUE, INCIDENT_GROUP_ID = FILTER_FIELDS.INCIDENT_GROUP_ID, IS_PRIMARY_INCIDENT = FILTER_FIELDS.IS_PRIMARY_INCIDENT;
    var ALIAS = SEARCH_FIELD_TYPES.ALIAS, DESCRIPTION = SEARCH_FIELD_TYPES.DESCRIPTION, EXTERNAL_ID = SEARCH_FIELD_TYPES.EXTERNAL_ID, INCIDENT_ID = SEARCH_FIELD_TYPES.INCIDENT_ID;
    var searchQuery = searchValue ? searchValue.trim() : '';
    var searchByAllFields = searchBy === SEARCH_FIELD_TYPES.ANY || !searchBy;
    if (searchQuery) {
        if (searchBy === SEARCH_FIELD_TYPES.CASE_NUMBER ||
            (searchByAllFields && verifyCaseNumber(searchQuery))) {
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: CASE_NUMBER,
                    queryType: MATCH,
                    value: searchQuery.includes('#')
                        ? searchQuery.replace('#', '')
                        : searchQuery
                }
            });
        }
        else if (searchBy === INCIDENT_ID ||
            (searchByAllFields && verifyIncidentId(searchQuery))) {
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: ID,
                    queryType: MATCH,
                    value: searchQuery
                }
            });
        }
        else if (searchBy === EXTERNAL_ID ||
            (searchByAllFields && verifyGensuiteId(searchQuery))) {
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: searchQuery
                }
            });
        }
        else if (searchBy === ALIAS ||
            (searchByAllFields && verifyUserAlias(searchQuery))) {
            var aliasValue = (_a = verifyUserAlias(searchQuery)) !== null && _a !== void 0 ? _a : searchQuery;
            composeFilters.push({
                composeOperator: COMPOSE_OPERATOR.AND,
                queryFilters: [
                    {
                        filterType: MUST,
                        queryParams: [
                            {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: 'incidentAmazonionAliasInvolved'
                            },
                            {
                                field: SEARCH_ATTRIBUTES_VALUE,
                                queryType: MATCH,
                                value: aliasValue
                            }
                        ]
                    }
                ]
            });
        }
        else if (searchBy === DESCRIPTION) {
            composeFilters.push({
                composeOperator: COMPOSE_OPERATOR.AND,
                queryFilters: [
                    {
                        filterType: MUST,
                        queryParams: [
                            {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: 'incidentDescription'
                            },
                            {
                                field: SEARCH_ATTRIBUTES_VALUE,
                                queryType: FUZZY_MATCH,
                                value: searchQuery
                            }
                        ]
                    }
                ]
            });
        }
        else if (searchBy === SEARCH_FIELD_TYPES.INCIDENT_GROUP_ID ||
            (searchByAllFields && verifyIncidentGroupId(searchQuery))) {
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: INCIDENT_GROUP_ID,
                    queryType: MATCH,
                    value: searchQuery.includes('#')
                        ? searchQuery.replace('#', '')
                        : searchQuery
                }
            });
            // Return primary incident as the only card
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: IS_PRIMARY_INCIDENT,
                    queryType: GREATER_THAN_EQUAL,
                    value: 'true'
                }
            });
        }
        else {
            // Search by all supported fields
            var searchTerms = searchQuery
                .split(/\s|-/)
                .filter(function (term) { return !!term; });
            queryFilters.push({
                filterType: MUST,
                queryParams: {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: FUZZY_MATCH,
                    value: getFilterValue(searchTerms)
                }
            });
        }
    }
    return { queryFilters: queryFilters, composeFilters: composeFilters };
}
/**
 * Helper method to build search queryFilters array
 *
 * @params {Object} filters
 * Filter values from FilterLayout to be applied to search query
 *
 * @params {Array} composeParams
 * Filter values for optional composeFilters; Compose filters are optional OR filters
 *
 * @returns {Object} QueryFilters
 * Filters to be passed to search Appsync request
 */
export function createQueryFilters(_a, composeParams) {
    var creator = _a.creator, createdBefore = _a.createdBefore, createdAfter = _a.createdAfter, createdBeforeRelative = _a.createdBeforeRelative, dateBefore = _a.dateBefore, dateAfter = _a.dateAfter, dateBeforeRelative = _a.dateBeforeRelative, dateCreated = _a.dateCreated, excludeId = _a.excludeId, externalService = _a.externalService, ids = _a.ids, incidentDate = _a.incidentDate, inGroup = _a.inGroup, groupId = _a.groupId, medicalStatus = _a.medicalStatus, caseConditions = _a.caseConditions, potentialSeverity = _a.potentialSeverity, principalBodyPart = _a.principalBodyPart, riskLevel = _a.riskLevel, searchQuery = _a.search, searchBy = _a.searchBy, severity = _a.severity, status = _a.status, 
    // Incident types
    employeeType = _a.employeeType, eventType = _a.eventType, eventSubType = _a.eventSubType, type = _a.type, 
    // scope fields
    Site = _a.Site, SiteType = _a["Site Type"], Org = _a.Org, SubOrg = _a.SubOrg, Region = _a.Region, Country = _a.Country, State = _a.State, location = _a.location, 
    // PARk
    parkStatus = _a.parkStatus;
    if (composeParams === void 0) { composeParams = []; }
    var MUST = FILTER_TYPE.MUST, MUST_NOT = FILTER_TYPE.MUST_NOT;
    var CONTAINS = QUERY_TYPE.CONTAINS, EXISTS = QUERY_TYPE.EXISTS, GREATER_THAN_EQUAL = QUERY_TYPE.GREATER_THAN_EQUAL, LESS_THAN_EQUAL = QUERY_TYPE.LESS_THAN_EQUAL, MATCH = QUERY_TYPE.MATCH;
    var CREATED_AT = FILTER_FIELDS.CREATED_AT, CREATOR = FILTER_FIELDS.CREATOR, DYNAMIC_SCOPE_NODE_ID = FILTER_FIELDS.DYNAMIC_SCOPE_NODE_ID, INCIDENT_DATE_TIME = FILTER_FIELDS.INCIDENT_DATE_TIME, ID = FILTER_FIELDS.ID, TYPE = FILTER_FIELDS.TYPE, POTENTIAL_SEVERITY_LEVEL = FILTER_FIELDS.POTENTIAL_SEVERITY_LEVEL, RISK_LEVEL = FILTER_FIELDS.RISK_LEVEL, STATUS = FILTER_FIELDS.STATUS, RECORDABLE = FILTER_FIELDS.RECORDABLE, SEARCH_ATTRIBUTES_KEY = FILTER_FIELDS.SEARCH_ATTRIBUTES_KEY, SEARCH_ATTRIBUTES_VALUE = FILTER_FIELDS.SEARCH_ATTRIBUTES_VALUE, SEVERITY_LEVEL = FILTER_FIELDS.SEVERITY_LEVEL;
    var CASE_NUMBER = SEARCH_FIELD_TYPES.CASE_NUMBER, EXTERNAL_ID = SEARCH_FIELD_TYPES.EXTERNAL_ID, INCIDENT_ID = SEARCH_FIELD_TYPES.INCIDENT_ID, INCIDENT_GROUP_ID = SEARCH_FIELD_TYPES.INCIDENT_GROUP_ID;
    var queryFilters = [];
    var composeFilters = Array.isArray(composeParams) ? composeParams : [];
    var totalCountFilters = [];
    if (searchQuery) {
        parseSearchQuery(searchQuery, searchBy, queryFilters, composeFilters);
    }
    // Search query is by id, caseNumber, or external id.  Statuses/types should not be omitted
    var isSearchingById = Boolean(groupId) ||
        Boolean(ids === null || ids === void 0 ? void 0 : ids.length) ||
        (Boolean(searchQuery) &&
            [CASE_NUMBER, EXTERNAL_ID, INCIDENT_ID, INCIDENT_GROUP_ID].includes(getSearchType(searchQuery))) ||
        (Boolean(searchBy) &&
            [CASE_NUMBER, EXTERNAL_ID, INCIDENT_ID, INCIDENT_GROUP_ID].includes(searchBy));
    if (excludeId) {
        queryFilters.push({
            filterType: MUST_NOT,
            queryParams: {
                field: ID,
                queryType: MATCH,
                value: excludeId
            }
        });
    }
    if (ids) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: ID,
                queryType: MATCH,
                value: ids
            }
        });
    }
    if (inGroup !== undefined) {
        queryFilters.push({
            filterType: inGroup ? MUST : MUST_NOT,
            queryParams: {
                field: INCIDENT_GROUP_ID,
                queryType: EXISTS,
                value: []
            }
        });
    }
    if (groupId) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_GROUP_ID,
                queryType: MATCH,
                value: groupId
            }
        });
    }
    // @todo abstract filter creation to reduce boiler plate
    if (creator && creator.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATOR,
                queryType: MATCH,
                value: creator
            }
        });
    }
    if (createdBefore) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATED_AT,
                queryType: LESS_THAN_EQUAL,
                value: getDateStringEndOfDay(createdBefore)
            }
        });
    }
    if (createdAfter) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATED_AT,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(createdAfter)
            }
        });
    }
    if (createdBeforeRelative) {
        var absoluteCreatedBefore = getAbsoluteDateValueFromRelativeDays({
            delta: createdBeforeRelative
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATED_AT,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(absoluteCreatedBefore)
            }
        });
    }
    if (dateBefore) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_DATE_TIME,
                queryType: LESS_THAN_EQUAL,
                value: getDateStringEndOfDay(dateBefore)
            }
        });
    }
    if (dateAfter) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_DATE_TIME,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(dateAfter)
            }
        });
    }
    if (dateBeforeRelative) {
        var absoluteDateBefore = getAbsoluteDateValueFromRelativeDays({
            delta: dateBeforeRelative
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_DATE_TIME,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(absoluteDateBefore)
            }
        });
    }
    if (dateCreated) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATED_AT,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(dateCreated)
            }
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: CREATED_AT,
                queryType: LESS_THAN_EQUAL,
                value: getDateStringEndOfDay(dateCreated)
            }
        });
    }
    if (incidentDate) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_DATE_TIME,
                queryType: GREATER_THAN_EQUAL,
                value: getDateStringStartOfDay(incidentDate)
            }
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: INCIDENT_DATE_TIME,
                queryType: LESS_THAN_EQUAL,
                value: getDateStringEndOfDay(incidentDate)
            }
        });
    }
    // incident type (Injury, Event, Near miss)
    if (type && type.length) {
        var typeFilters = [];
        var singleIncidentTypes = type.filter(function (incidentType) {
            return incidentType !== INCIDENT_TYPES.EVENT_AND_INJURY;
        });
        if (singleIncidentTypes.length) {
            typeFilters.push({
                filterType: MUST,
                queryParams: {
                    field: TYPE,
                    queryType: MATCH,
                    value: singleIncidentTypes
                }
            });
        }
        // Type is multi-incident
        if (type.includes(INCIDENT_TYPES.EVENT_AND_INJURY)) {
            typeFilters.push({
                filterType: MUST,
                queryParams: [
                    {
                        field: SEARCH_ATTRIBUTES_KEY,
                        queryType: MATCH,
                        value: 'isMultiIncident'
                    },
                    {
                        field: SEARCH_ATTRIBUTES_VALUE,
                        queryType: GREATER_THAN_EQUAL,
                        value: 'true'
                    }
                ]
            });
        }
        composeFilters.push({
            composeOperator: COMPOSE_OPERATOR.OR,
            queryFilters: typeFilters
        });
    }
    // injury employee type (employee, contractor, visitor)
    if (employeeType === null || employeeType === void 0 ? void 0 : employeeType.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'employeeType'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: employeeType
                }
            ]
        });
    }
    if (eventType === null || eventType === void 0 ? void 0 : eventType.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'eventType'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: eventType
                }
            ]
        });
    }
    if (eventSubType === null || eventSubType === void 0 ? void 0 : eventSubType.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'eventSubType'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: eventSubType
                }
            ]
        });
    }
    if (riskLevel && riskLevel.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: RISK_LEVEL,
                queryType: MATCH,
                value: riskLevel
            }
        });
    }
    if (severity && severity.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: SEVERITY_LEVEL,
                queryType: MATCH,
                value: severity
            }
        });
    }
    if (potentialSeverity === null || potentialSeverity === void 0 ? void 0 : potentialSeverity.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: POTENTIAL_SEVERITY_LEVEL,
                queryType: MATCH,
                value: potentialSeverity
            }
        });
    }
    if (principalBodyPart === null || principalBodyPart === void 0 ? void 0 : principalBodyPart.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'principalBodyPart'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: CONTAINS,
                    value: principalBodyPart
                }
            ]
        });
    }
    if (medicalStatus === null || medicalStatus === void 0 ? void 0 : medicalStatus.length) {
        var NOT_STARTED_1 = INCIDENT_MEDICAL_STATUS.NOT_STARTED;
        var standardMedicalStatuses = medicalStatus.filter(function (filterValue) { return filterValue !== NOT_STARTED_1; });
        var defaultFilter = {
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'latestMedicalStatus'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: medicalStatus
                }
            ]
        };
        if (medicalStatus.includes(NOT_STARTED_1)) {
            /**
             * Not started is not a medical status
             * to search for this need to use a compose filter for Injury/Illness types
             * that do not have customSearchAttributes.latestMedicalStatus set
             * latestMedicalStatus will be set if Initial Encounter or Followup is completed
             * @todo We should support a not-started status to simplify this search; Will need to be added to Injury types on creation before Initial encounter is submitted
             *
             */
            composeFilters.push({
                composeOperator: COMPOSE_OPERATOR.AND,
                queryFilters: [
                    {
                        filterType: MUST_NOT,
                        queryParams: [
                            {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: 'latestMedicalStatus'
                            },
                            {
                                field: TYPE,
                                queryType: MATCH,
                                value: [
                                    INCIDENT_TYPES.NEAR_MISS,
                                    INCIDENT_TYPES.EVENT
                                ]
                            }
                        ]
                    }
                ]
            });
            // If user selects additional medical statuses, these need to be included as an additional compose (OR) filter
            if (standardMedicalStatuses.length) {
                composeFilters.push({
                    composeOperator: COMPOSE_OPERATOR.AND,
                    queryFilters: [defaultFilter]
                });
            }
        }
        else {
            // if not-started status is not included, use standard queryFilters
            queryFilters.push(defaultFilter);
        }
    }
    // Append all case conditions with an AND operator
    if (caseConditions === null || caseConditions === void 0 ? void 0 : caseConditions.length) {
        caseConditions.forEach(function (condition) {
            switch (condition) {
                case INCIDENT_CASE_CONDITION.RECORDABLE:
                    queryFilters.push({
                        filterType: MUST,
                        queryParams: {
                            field: RECORDABLE,
                            queryType: GREATER_THAN_EQUAL,
                            value: 'true'
                        }
                    });
                    break;
                case INCIDENT_CASE_CONDITION.WORK_RELATED:
                    queryFilters.push({
                        filterType: MUST,
                        queryParams: [
                            {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: CaseConditionCustomSearchAttributeKey.NON_WORK_RELATED
                            },
                            {
                                field: SEARCH_ATTRIBUTES_VALUE,
                                queryType: MATCH,
                                value: CaseConditionNonWorkRelatedValue.FALSE
                            }
                        ]
                    });
                    break;
                // The filter requirement is any DAFW start date, end date, both are populated
                case INCIDENT_CASE_CONDITION.ALL_DAFW:
                    if (!queryFilters.includes(dafwStartDatePresentFilter)) {
                        queryFilters.push(dafwStartDatePresentFilter);
                    }
                    break;
                // The filter requirement is only has DAFW start date but no end date (cannot populate end without start date
                case INCIDENT_CASE_CONDITION.PENDING_DAFW_END_DATE:
                    if (!queryFilters.includes(dafwStartDatePresentFilter)) {
                        queryFilters.push(dafwStartDatePresentFilter);
                    }
                    queryFilters.push(dafwEndDateNotPresentFilter);
                    break;
                // The filter requirement is any RWA start date, end date, both are populated
                case INCIDENT_CASE_CONDITION.ALL_RWA:
                    if (!queryFilters.includes(rwaStartDatePresentFilter)) {
                        queryFilters.push(rwaStartDatePresentFilter);
                    }
                    break;
                // The filter requirement is only has RWA start date but no end date (cannot populate end without start date)
                case INCIDENT_CASE_CONDITION.PENDING_RWA_END_DATE:
                    if (!queryFilters.includes(rwaStartDatePresentFilter)) {
                        queryFilters.push(rwaStartDatePresentFilter);
                    }
                    queryFilters.push(rwaEndDateNotPresentFilter);
                    break;
                case INCIDENT_CASE_CONDITION.AMCARE_BYPASS:
                    queryFilters.push({
                        filterType: MUST,
                        queryParams: [
                            {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: CaseConditionCustomSearchAttributeKey.AMCARE_BYPASS
                            },
                            {
                                field: SEARCH_ATTRIBUTES_VALUE,
                                queryType: MATCH,
                                value: [
                                    CaseConditionAmcareBypassValue.YES,
                                    CaseConditionAmcareBypassValue.Yes
                                ]
                            }
                        ]
                    });
                    break;
                default:
                    break;
            }
        });
    }
    if (status && status.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: STATUS,
                queryType: MATCH,
                value: status
            }
        });
    }
    else if (isSearchingById) {
        /**
         * If searching by specific caseId, incidentId
         * allow search results regardless of incident status
         * Should still omit DELETED incidents
         */
        queryFilters.push({
            filterType: MUST_NOT,
            queryParams: {
                field: STATUS,
                queryType: MATCH,
                value: [INCIDENT_STATUS.ARCHIVED, INCIDENT_STATUS.DELETED]
            }
        });
    }
    else {
        /**
         * Filter our incidents with certain statuses by default
         * If a manually filtered status is set, these will still be filtered out
         */
        queryFilters.push({
            filterType: MUST_NOT,
            queryParams: {
                field: STATUS,
                queryType: MATCH,
                value: [
                    INCIDENT_STATUS.ARCHIVED,
                    INCIDENT_STATUS.CLOSED,
                    INCIDENT_STATUS.DELETED,
                    INCIDENT_STATUS.READY
                ]
            }
        });
    }
    if (location) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: SEARCH_ATTRIBUTES_VALUE,
                queryType: MATCH,
                value: location
            }
        });
    }
    if (Site) {
        totalCountFilters.push({
            filterType: MUST,
            queryParams: {
                field: DYNAMIC_SCOPE_NODE_ID,
                queryType: MATCH,
                value: getFilterValue(Site)
            }
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: DYNAMIC_SCOPE_NODE_ID,
                queryType: MATCH,
                value: getFilterValue(Site)
            }
        });
    }
    if (SiteType) {
        totalCountFilters.push({
            filterType: MUST,
            queryParams: {
                field: DYNAMIC_SCOPE_NODE_ID,
                queryType: MATCH,
                value: getFilterValue(SiteType)
            }
        });
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: DYNAMIC_SCOPE_NODE_ID,
                queryType: MATCH,
                value: getFilterValue(SiteType)
            }
        });
    }
    if (parkStatus === null || parkStatus === void 0 ? void 0 : parkStatus.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'parkStatus'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: CONTAINS,
                    value: parkStatus
                }
            ]
        });
    }
    // exclude Gensuite by default
    if (externalService === null || externalService === void 0 ? void 0 : externalService.length) {
        queryFilters.push({
            filterType: MUST,
            queryParams: [
                {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'EXTERNAL_SERVICE'
                },
                {
                    field: SEARCH_ATTRIBUTES_VALUE,
                    queryType: MATCH,
                    value: externalService
                }
            ]
        });
    }
    else if (composeFilters.length > 0) {
        if (!searchQuery && !isSearchingById) {
            composeFilters = composeFilters.flatMap(function (filter) { return ([{
                    composeOperator: COMPOSE_OPERATOR.AND,
                    queryFilters: __spreadArray(__spreadArray([], filter.queryFilters, true), [
                        {
                            filterType: MUST,
                            queryParams: [
                                {
                                    field: SEARCH_ATTRIBUTES_KEY,
                                    queryType: MATCH,
                                    value: 'EXTERNAL_SERVICE'
                                },
                                {
                                    field: SEARCH_ATTRIBUTES_VALUE,
                                    queryType: MATCH,
                                    value: [EXTERNAL_INCIDENT_TYPES.EMT]
                                }
                            ]
                        }
                    ], false)
                }, {
                    composeOperator: COMPOSE_OPERATOR.AND,
                    queryFilters: __spreadArray(__spreadArray([], filter.queryFilters, true), [
                        {
                            filterType: MUST_NOT,
                            queryParams: {
                                field: SEARCH_ATTRIBUTES_KEY,
                                queryType: MATCH,
                                value: 'EXTERNAL_SERVICE'
                            }
                        }
                    ], false)
                }]); });
        }
    }
    else {
        var externalFilters = [
            // Is imported from EMT
            {
                filterType: MUST,
                queryParams: [
                    {
                        field: SEARCH_ATTRIBUTES_KEY,
                        queryType: MATCH,
                        value: 'EXTERNAL_SERVICE'
                    },
                    {
                        field: SEARCH_ATTRIBUTES_VALUE,
                        queryType: MATCH,
                        value: [EXTERNAL_INCIDENT_TYPES.EMT]
                    }
                ]
            },
            // Not imported
            {
                filterType: MUST_NOT,
                queryParams: {
                    field: SEARCH_ATTRIBUTES_KEY,
                    queryType: MATCH,
                    value: 'EXTERNAL_SERVICE'
                }
            }
        ];
        composeFilters.push({
            composeOperator: COMPOSE_OPERATOR.OR,
            queryFilters: externalFilters
        });
    }
    // from global scope map
    [Org, SubOrg, Region, Country, State]
        .filter(function (field) { return field; })
        .forEach(function (field) {
        queryFilters.push({
            filterType: MUST,
            queryParams: {
                field: DYNAMIC_SCOPE_NODE_ID,
                queryType: MATCH,
                value: getFilterValue(field)
            }
        });
    });
    return { composeFilters: composeFilters, queryFilters: queryFilters, totalCountFilters: totalCountFilters };
}
export function reduceAttributesToObject(attributes, attr) {
    var _a;
    return __assign(__assign({}, attributes), (_a = {}, _a[attr.key] = attr.value, _a));
}
export function incidentWithCustomSearchAttributesMap(incident) {
    return __assign(__assign({}, incident), { customAttributeMap: get(incident, 'customSearchAttributes', [])
            // @ts-ignore
            .reduce(reduceAttributesToObject, {}) });
}
export function incidentsWithCustomSearchAttributesMap(incidents) {
    return incidents.map(function (incident) {
        return incidentWithCustomSearchAttributesMap(incident);
    });
}
