import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    loadingBanner: {
        height: 260,
        transform: 'inherit'
    },
    loadingField: {
        height: 60,
        transform: 'inherit'
    },
    loadingFilter: {
        height: 500,
        transform: 'inherit'
    }
}));

/**
 * @private
 *
 * @description
 * Default skeleton page to render while Incident container is in a loading state.
 */
const IncidentSkeleton = () => {
    const {
        loadingBanner,
        loadingField,
        loadingFilter
    } = useStyles();

    return (
        <Grid container data-testid="IncidentSkeleton" spacing={2}>
            <Grid item xs={3}>
                <Skeleton className={loadingFilter} />
            </Grid>

            <Grid item xs={9}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Skeleton className={loadingBanner} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Skeleton className={loadingField} />
                    </Grid>

                    <Grid item xs={12}>
                        <Skeleton className={loadingField} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default IncidentSkeleton;
