const ANSWER_CONFLICT = 'ANSWER_CONFLICT';
const REQUIRED = 'required';
const INVALID = 'invalid';
const invalidDate = 'invalidDate';
const maxDate = 'maxDate';
const minDate = 'minDate';

const NOT_APPLICABLE = 'NOT_APPLICABLE';

export const ERROR_TYPES = {
    ANSWER_CONFLICT,
    INVALID,
    invalidDate,
    maxDate,
    minDate,
    NOT_APPLICABLE,
    REQUIRED
};
