var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, Grid, IconButton, SwipeableDrawer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from '@amzn/austin-core';
import { makeStyles } from 'tss-react/mui';
import { buildHelpPanelContentSectionsFromAllowedAttachmentTypes } from './util';
export var HELP_PANEL_CONTENT = {
    MEDICAL_ATTACHMENTS: function (data) { return ({
        title: 'ehs_incidents_label_phaseMedicalAttachments',
        subtitle: 'ehs_incidents_label_fileType',
        sections: buildHelpPanelContentSectionsFromAllowedAttachmentTypes(data === null || data === void 0 ? void 0 : data.allowedAttachmentTypes)
    }); },
    INVESTIGATION_ATTACHMENTS: function (data) { return ({
        title: 'ehs_incidents_label_phaseInvestigation',
        subtitle: 'ehs_incidents_label_fileType',
        sections: buildHelpPanelContentSectionsFromAllowedAttachmentTypes(data === null || data === void 0 ? void 0 : data.allowedAttachmentTypes)
    }); },
    REGULATORY_ATTACHMENTS: function (data) { return ({
        title: 'ehs_incidents_label_phaseRegulatory',
        subtitle: 'ehs_incidents_label_fileType',
        sections: buildHelpPanelContentSectionsFromAllowedAttachmentTypes(data === null || data === void 0 ? void 0 : data.allowedAttachmentTypes)
    }); }
};
var useStyles = makeStyles()(function (_a) {
    var spacing = _a.spacing;
    return ({
        root: {
            padding: spacing(2)
        },
        item: {
            padding: spacing(1),
            margin: spacing(0),
            width: '100%',
            maxWidth: 400
        }
    });
});
export var IncidentHelpPanel = function (_a) {
    var id = _a.id, onClose = _a.onClose, data = _a.data;
    var getMessage = useIntl().getMessage;
    var classes = useStyles().classes;
    var _b = useMemo(
    // prettier-ignore
    function () {
        return (id && HELP_PANEL_CONTENT[id] ? HELP_PANEL_CONTENT[id](data) : { title: id, subtitle: undefined, sections: [] });
    }, [id, data]), title = _b.title, subtitle = _b.subtitle, sections = _b.sections;
    return (_jsx(SwipeableDrawer, __assign({ open: !!id, onClose: onClose, onOpen: function () { }, anchor: "right", disableScrollLock: true, "data-testid": "IncidentHelpPanel" }, { children: _jsxs(Box, __assign({ "data-testid": "IncidentHelpPanelContent", className: classes.root }, { children: [_jsxs(Grid, __assign({ container: true, className: classes.item }, { children: [_jsxs(Grid, __assign({ item: true, xs: 11 }, { children: [_jsx(Typography, __assign({ variant: "h2" }, { children: title ? getMessage(title) : title })), _jsx(Typography, __assign({ variant: "subtitle1" }, { children: subtitle ? getMessage(subtitle) : subtitle }))] })), _jsx(Grid, __assign({ item: true, xs: 1 }, { children: _jsx(IconButton, __assign({ onClick: onClose, "data-testid": "IncidentHelpPanel-closeButton" }, { children: _jsx(CloseIcon, {}) })) }))] })), sections.map(function (_a) {
                    var description = _a.description, title = _a.title;
                    return (_jsxs(Grid, __assign({ item: true, "data-testid": "IncidentHelpPanel-section-".concat(title), className: classes.item }, { children: [_jsx(Typography, __assign({ variant: "h4" }, { children: getMessage(title) })), _jsx(Typography, { children: getMessage(description) })] }), "IncidentHelpPanel-section-".concat(title)));
                })] })) })));
};
