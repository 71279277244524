import { useState } from 'react';

import useIncidentsService from './useIncidentsService';

const useSiteLaunchCheck = () => {
    const IncidentService = useIncidentsService();

    const [isLoading, setIsLoading] = useState(false);
    const [isLaunched, setIsLaunched] = useState();
    const [launchDate, setLaunchDate] = useState();
    const [isSiteClosed, setIsSiteClosed] = useState();
    const [siteClosureDate, setSiteClosureDate] = useState();
    const [siteName, setSiteName] = useState();

    async function getSiteLaunchData(siteId) {
        if (siteId) {
            setIsLoading(true);
            const data = await IncidentService.getIncidentLaunchDateForSite({
                id: siteId
            });

            setSiteName(data?.siteName);
            setIsLaunched(data?.siteIsLaunched);
            setLaunchDate(data?.siteLaunchDate);

            setIsSiteClosed(data?.siteClosed);
            setSiteClosureDate(data?.siteClosureDate);
        } else {
            // global users can access
            setIsLaunched(true);
            setSiteName();
            setLaunchDate();
            setIsSiteClosed(false);
            setSiteClosureDate();
        }

        setIsLoading(false);
    }

    return {
        isLoading,
        launchDate,
        isSiteClosed,
        isSiteLaunched: isLaunched,
        siteClosureDate,
        siteName,
        getSiteLaunchData
    };
};

export default useSiteLaunchCheck;
