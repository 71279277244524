/* istanbul ignore file */
import { MAX_FILE_SIZE, MIME_TYPES } from '@amzn/austin-core';
/**
 * @note
 * All exports are marked as private as they are intended to only be used by the new FileAttachments
 * UI component. In order to promote a consistent UX we don't want module owners rolling out their
 * own implementations hence the reason we are not exposing any of this functionality. Please don't
 * copy/paste this code either. If you need functionality exposed, then cut the DAIS team a ticket
 * and provide proper justification.
 */
/**
 * This is based on the mime types that we've defined in MIME_TYPES. It will primarily be used
 * to render an appropriate file type icon in the UI and only for legacy file attachments that
 * don't have the mime type persisted. We mostly care about distinguishing images and videos
 * from other file types, but files are being included below just in case we want to start rendering
 * more specific icons for well known types.
 *
 * @private
 */
export var MIME_TYPES_BY_FILE_EXTENSION = {
    // Files
    csv: 'text/csv',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    eml: 'message/rfc822',
    msg: 'application/vnd.ms-outlook',
    pdf: 'application/pdf',
    pot: 'application/vnd.ms-powerpoint',
    ppa: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf: 'application/rtf',
    txt: 'text/plain',
    xla: 'application/vnd.ms-excel',
    xls: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // Images
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    // Videos
    avi: 'video/x-msvideo',
    mkv: 'video/x-matroska',
    mov: 'video/quicktime',
    mp3: 'video/mpeg',
    mp4: 'vidoe/mp4',
    mpeg: 'video/mpeg',
    ts: 'video/MP2T'
};
/**
 * Takes in a file name and returns the file extension.
 *
 * @private
 */
export function getFileExtension(fileName) {
    if (fileName === void 0) { fileName = ''; }
    if (!fileName.includes('.') || fileName.startsWith('.')) {
        return '';
    }
    return fileName
        .toLowerCase()
        .split('.')
        .pop();
}
/**
 * Helper function which returns the maximum allowed file size based on the mime type. If maxFileSize
 * is passed then that value takes precendence over the maximum sizes we've defined for mime types.
 *
 * @private
 */
export function getMaxFileSizeInBytes(maxFileSize, mimeType) {
    if (maxFileSize === void 0) { maxFileSize = 0; }
    if (mimeType === void 0) { mimeType = ''; }
    if (maxFileSize) {
        return maxFileSize;
    }
    if (MIME_TYPES.IMAGE.includes(mimeType)) {
        return MAX_FILE_SIZE.IMAGE;
    }
    return MAX_FILE_SIZE.FILE;
}
/**
 * This function attempts to resolve the mime type for a given file extension.
 * While browsers usually include the mime type when files are selected for upload,
 * there are certain situations where the mime type isn't known due to the
 * underlying operating system (or browser) not understanding certain file formats.
 *
 * @private
 */
export function getMimeType(fileName) {
    if (fileName === void 0) { fileName = ''; }
    var extension = getFileExtension(fileName) || '';
    var mimeType = '';
    switch (extension) {
        case 'msg':
            mimeType = 'application/vnd.ms-outlook';
            break;
        case 'mkv':
            mimeType = 'video/x-matroska';
            break;
        case 'eml':
            mimeType = 'message/rfc822';
            break;
        default:
            mimeType = MIME_TYPES_BY_FILE_EXTENSION[extension] || '';
            break;
    }
    return mimeType;
}
