/* istanbul ignore file */

/**
 * @description
 * ## Summary
 * This is an interim solution to allow components to be iterated with a mock API layer.
 * Each api call can be removed as the Graphql API becomes available in AppSync
 */
import { executeRequest as commonExecuteRequest } from '@amzn/austin-core';

// create forms are defined in UI only, there is no corresponding form template from backend api
import {
    getIncidentCreateForm,
    getNonAmazonianCreateForm
} from './formTemplates';

const api = {
    getIncidentCreateForm,
    getNonAmazonianCreateForm
};

async function executeRequest(operationName, params, options = {}) {
    let result;
    const { useCache, useMockAPI } = options;

    // if operation name is not supported by BE AppSync yet (i.e. get createIncident form)
    if (
        useMockAPI &&
        api[operationName] &&
        typeof api[operationName] === 'function'
    ) {
        result = await Promise.resolve({
            [operationName]: api[operationName].call(this, params)
        });
    } else {
        result = await commonExecuteRequest(operationName, params, {
            useCache
        });
    }

    return result;
}

export { executeRequest };
