const defaultFormFields = `
  id
  version
`;

const answerFields = `
  answers {
    answerList
    answerOption,
    questionId,
    detail
  }
`;

export const saveFormFields = `
  ${answerFields}
  ${defaultFormFields}

  assignee
  locks {
    lockIntent
    lockOwnerId
  }
  status
  updatedAt
`;

export const getFormQuery = `query GetForm($input: GetFormsInput!) {
  getForm(input: $input) {
    formsList {
      ${answerFields}
      ${defaultFormFields}
      formQuestions {
        id
        answerChoices
      }
      formTemplate {
        formTemplate {
          title
          formGuidance {
            text
          }
          formTemplateQuestions {
            id
            sectionId
          }
          sections {
            id
            guidance {
              text
            }
            title
          }
        }
      }
      questions {
        questions {
          id
          externalId
          answerTemplate
          hidden
          questionText {
            text
            metadata {
              key
              value
            }
            answerLabels {
              key
              value
            }
            answerGuidance {
              answerId
              guidance {
                text
              }
            }
            guidance {
                text
            }
          }
          metadata {
            key
            value
          }
          category {
            name
          }
          metadata {
            key
            value
          }
          version
        }
      }
      questionSet {
        questionSetIdentifier{
          id
        }
        questionSet {
          questionRelationships {
            questionId
            optional
            followUpQuestions {
              answerId
              followUpQuestionIds
            }
          }
        }
      }
      assignee
      locale
      locks {
        lockIntent
        lockOwnerId
      }
      status
      createdAt
      updatedAt
      scopes {
        nodeIds {
          id
        }
      }
    }
  }
}`;

/**
 * This is the new API defined in https://i.amazon.com/issues/INCIDENTS-4246
 * @type {string}
 */
export const getIncidentFormQuery = `query GetIncidentForm($request: GetIncidentFormsInput!) {
  getIncidentForms(request: $request) {
      ${answerFields}
      ${defaultFormFields}
      formTemplate {
        formTemplate {
          title
          formGuidance {
            text
          }
          formTemplateQuestions {
            id
            sectionId
          }
          sections {
            id
            guidance {
              text
            }
            title
          }
        }
      }
      questions {
        questions {
          id
          externalId
          answerTemplate
          questionText {
            metadata {
              key
              value
            }
            text
            answerLabels {
              key
              value
            }
            answerGuidance {
              answerId
              guidance {
                text
              }
            }
            guidance {
                text
            }
          }
          metadata {
            key
            value
          }
          hidden
          category {
            name
          }
          metadata {
            key
            value
          }
          version
        }
      }
      questionSet {
        questionSetIdentifier{
          id
        }
        questionSet {
          questionRelationships {
            questionId
            optional
            followUpQuestions {
              answerId
              followUpQuestionIds
            }
          }
        }
      }
      assignee
      locale
      locks {
        lockIntent
        lockOwnerId
      }
      status
      createdAt
      updatedAt
      scopes {
        nodeIds {
          id
        }
      }
    }
}`;

export const saveFormQuery = `mutation SaveForm($input: UpdateFormInput!) {
  updateForm(input: $input) {
    form {
      ${saveFormFields}
    }
  }
}
`;

/**
 * This is the new API defined in https://i.amazon.com/issues/INCIDENTS-4246
 * @type {string}
 */
export const saveAndSubmitIncidentFormQuery = `mutation UpdateIncidentForm($request: UpdateIncidentFormInput!) {
  updateIncidentForm(request: $request) {
    ${saveFormFields}
  }
}
`;

export const scheduleAttachmentForDeletionMutation = `mutation  scheduleAttachmentForDeletion($input: ScheduleAttachmentDeletionRequestInput!) {
    deletionRequests: scheduleAttachmentForDeletion(input: $input) {
        scheduledForDeletion
        scheduledDateTime
    }
  }
`;

export const submitFormQuery = `mutation SubmitForm($input: UpdateFormInput!) {
  submitForm(input: $input) {
    form {
      ${saveFormFields}
    }
  }
}
`;

export const createFollowupQuery = `mutation CreateFollowup($input: CreateFollowUpAssessmentRequestInput!) {
  createFollowUpAssessment(input: $input) {
    formId
  }
}
`;

export const getFollowupQuery = `query GetFollowup($input: GetFollowUpInput!) {
  getFollowUp(input: $input) {
    assessments {
      completedAt
      formId
      reportedBy
      type
      customAssessmentAttributes {
        key
        value
      }
    }
    externalEntityId
    id
    version
  }
}
`;

export const getSiteScopeQuery = `query getSiteConfigContextFromAttributeIds($input: AttributeIdsWrapperInput!) {
  results: getSiteConfigContextFromAttributeIds(input: $input) {
    nodeIds {
      id,
      isHierarchyNode,
      tenantHierarchyId
    }
  }
}
`;

export const getRcaQuery = `query GetRca($input: GetRcaInput!) {
  getRca(input: $input) {
    createdAt
    externalEntityId
    formList {
      formId
      formType
      formVersion
    }
    id
    scope {
      nodeIds {
        id
      }
    }
    updatedAt
    version
  }
}
`;

export const submitRcaQuery = `mutation SubmitRca($input: SubmitRcaRequestInput!) {
  submitRca(input: $input) {
    id
    phases {
      type
      status
    }
  }
}
`;

export const addRootCauseFormQuery = `mutation AddRootCauseForm($input: AddRootCauseFormInput!) {
  addRootCauseForm(input: $input) {
    formList {
      formId
    }
  }
}
`;

export const removeRootCauseFormQuery = `mutation RemoveRootCauseForm($input: RemoveRootCauseFormInput!) {
  removeRootCauseForm(input: $input) {
    formList {
      formId
    }
  }
}
`;

export const submitApprovalQuery = `mutation SubmitApproval($input: SubmitApprovalRequestInput!) {
  submitApproval(input: $input) {
    phases {
      name
      type
      stages {
        name
        type
        formInfo {
          formId
        }
        approvalInfo {
            approved
            approver
            details
            submissionDate
        }
      }
    }
    version
  }
}`;

export const createRcaQuery = `mutation CreateRca($input: CreateRcaRequestInput!) {
  createRca(input: $input) {
    createdAt
    externalEntityId
    formList {
      formId
      formType
      formVersion
    }
    id
    scope {
      nodeIds {
        id
      }
    }
    updatedAt
    version
  }
}`;
