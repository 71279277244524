var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import pick from 'lodash/pick';
import { MESSAGE_TYPE, useIntl, useMessages, useUserProfile, TENANT_HIERARCHY_NODE_NAMES } from '@amzn/austin-core';
import { useErrorMessages } from './useErrorMessages';
import { IncidentsService } from '../../services/Incidents';
import { useDashboard } from '../../components/Dashboard/hooks/useDashboard';
var ORG = TENANT_HIERARCHY_NODE_NAMES.ORG, SUBORG = TENANT_HIERARCHY_NODE_NAMES.SUBORG, REGION = TENANT_HIERARCHY_NODE_NAMES.REGION, SITE_TYPE = TENANT_HIERARCHY_NODE_NAMES.SITE_TYPE, SITE = TENANT_HIERARCHY_NODE_NAMES.SITE;
/**
 * @module
 * common/hooks/useIncidentsService
 *
 * Hook that calls the IncidentsService factory function that exposes methods to
 * make AppSync requests. This hook specifies a default `onError` handler.
 * It allows overriding the `onError` handler and any config to the IncidentsService
 * factory function.
 *
 * ```jsx
 * // useIncidentsService using default config
 *
 * import useIncidentsService from 'common/hooks/useIncidentsService;
 *
 * const IncidentsService = useIncidentsService();
 * const [incident, setIncident] = useState();
 *
 * useEffect(() => {
 *     getIncident(incidentId);
 * }, [incidentId]);
 *
 * async function getIncident() {
 *     const data = await IncidentService.getIncident({
 *         id: incidentId
 *     });
 *
 *     setIncident(data);
 * }
 *
 * return <Incident {...incident} />;
 * ```
 *
 * ```jsx
 * // useIncidentsService using custom config
 *
 * import useIncidentsService from 'common/hooks/useIncidentsService;
 *
 * const IncidentsService = useIncidentsService({onError: handleError});
 * const [incident, setIncident] = useState();
 *
 * useEffect(() => {
 *     getIncident(incidentId);
 * }, [incidentId]);
 *
 * function handleError(err) {
 *     console.error(err)
 * }
 *
 * async function getIncident() {
 *     const data = await IncidentService.getIncident({
 *         id: incidentId
 *     });
 *
 *     setIncident(data);
 * }
 *
 * return <Incident {...incident} />;
 * ```
 *
 * @prop {Object} [config]
 * Config object to pass to IncidentsService
 *
 * @returns {Object}
 * Returns methods to make AppSync calls
 */
/**
 * Proposed error structure from API
 * @returns
 * {
 *    text: 'The specified entity could not be found.',
 *    localizationKey: 'ehs_incidents_itemNotFound',
 *    localizationParams: [
 *      {
 *        key: 'incidentId',
 *        value: 'jaijeoajea'
 *      }
 *    ]
 * }
 */
var useIncidentsService = function (config) {
    if (config === void 0) { config = {}; }
    var enqueueMessage = useMessages().enqueueMessage;
    var intl = useIntl().intl;
    /**
     * allowed search data
     */
    var _a = useDashboard().canViewAllFields, canViewAllFields = _a === void 0 ? false : _a;
    var getUsername = useUserProfile().getUsername;
    var getErrorMessage = useErrorMessages().getErrorMessage;
    var Service = IncidentsService(__assign({ onError: onRequestError }, config));
    /**
     * Handles request errors, displays message component
     * @param {Object} error
     * Error object from AppSync request
     */
    function onRequestError(error) {
        // eslint-disable-next-line no-console
        console.error(error);
        var _a = getErrorMessage(error), id = _a.id, defaultText = _a.defaultText, params = _a.params;
        enqueueMessage({
            message: id ? intl.formatMessage({ id: id }, params) : defaultText,
            messageType: MESSAGE_TYPE.ERROR,
            autoHideDuration: 30000
        });
    }
    function searchIncidents(searchInput) {
        return __awaiter(this, void 0, void 0, function () {
            var restrictFields, filters, input, incidents;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        restrictFields = !canViewAllFields;
                        filters = searchInput.queryFilters || {};
                        if (restrictFields) {
                            // only allow search by subset of fields
                            // user can only view incidents they have created
                            filters = pick(filters, [
                                ORG,
                                REGION,
                                SITE_TYPE,
                                SUBORG,
                                SITE,
                                'dateAfter',
                                'dateBefore',
                                'status'
                            ]);
                            filters.creator = [getUsername()];
                        }
                        input = __assign(__assign({}, searchInput), { queryFilters: filters });
                        return [4 /*yield*/, Service.searchIncidents(input, restrictFields)];
                    case 1:
                        incidents = _a.sent();
                        return [2 /*return*/, incidents];
                }
            });
        });
    }
    return __assign(__assign({}, Service), { searchIncidents: searchIncidents });
};
export default useIncidentsService;
