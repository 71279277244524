/* istanbul ignore file */
import { find } from 'lodash';
import { addDays } from 'date-fns';
import { DATE_PICKER_FORMAT, convertToDateObject, getDateStringForPicker } from '@amzn/austin-core';
import { RISK_CRITICALITY } from '@amzn/austin-module-actions';
import { TERM } from '../constants';
import { RISK_ASSESSMENT_CONFIG } from './risk-assessment';
/**
 * Enum for the Action Extension Status that is part of the Action entity.
 *
 * @private
 */
var ACTION_EXTENSION_STATUS = {
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED'
};
/**
 * Method that will set the due date based on the criticality and type of action
 *
 * @param {object} action
 * @param {string} parentFindingCriticality
 * @returns {object}
 */
export function getDueDateInformation(action, parentFindingCriticality) {
    var createdAt = action.createdAt, criticality = action.criticality, _a = action.extensions, extensions = _a === void 0 ? [] : _a, term = action.term;
    var riskAssessmentConfig = RISK_ASSESSMENT_CONFIG();
    // Using the action create date, this may change, leaving the commented code above
    var currentIssueCreateDate = new Date(createdAt);
    // If no criticality, use the minimum criticality, this will allow users to select the date, once the risk assessment is selected
    // the criticality is calculated and the validation will take care of verifying the due dates selected
    var currentActionCriticality = parentFindingCriticality || criticality || RISK_CRITICALITY.NEGLIGIBLE;
    var pendingExtensionRequest = getDueDateExtensionInformation(extensions)
        .pendingExtensionRequest;
    // Use the criticality and the action term to determine what is the standard due date using the issue create date as the base of the
    // sla calculation. If we don't have the criticality, then default to 1 day sla.
    var currentRiskConfig = riskAssessmentConfig.get(currentActionCriticality);
    var daysToAddForStandard = currentRiskConfig.sla[term].standard;
    return {
        enabled: term === TERM.LONG && !pendingExtensionRequest,
        standard: getDateStringForPicker(addDays(currentIssueCreateDate, daysToAddForStandard))
    };
}
/**
 * Method that will retrieve information related to the extension, the information currently
 * provided will be a flag that will indicate if there is a pending extension (in progress) and
 * if that is true then it will also provide the approver.
 *
 * @private
 * @param {array} extensions
 * @returns {object}
 */
function getDueDateExtensionInformation(extensions) {
    var result = {
        approver: '',
        pendingExtensionRequest: false
    };
    // Only the latest request is the one that can be pending approval
    var pendingExtension = find(extensions, function (extension) { return extension.status === ACTION_EXTENSION_STATUS.PENDING; });
    if (pendingExtension) {
        result = {
            approver: pendingExtension.approver,
            pendingExtensionRequest: true
        };
    }
    return result;
}
export function isExtensionRequired(standardDueDate, dueDate) {
    var convertedDueDate = convertToDateObject(dueDate, DATE_PICKER_FORMAT);
    var convertedStandardDueDate = convertToDateObject(standardDueDate, DATE_PICKER_FORMAT);
    return convertedDueDate > convertedStandardDueDate;
}
