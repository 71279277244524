import { phaseStageFragment } from './incidents';

export const searchFileIdsByAssociatedIdQuery = `query searchFileIdsByAssociatedId(
    $associatedIds: [String!] !
  ) {
    files: searchFileStorageMetadata(
      input: {
        search: {
          pageInfo: {
            from: 0
            size: 100
          },
          queryFilters: [{
            queryParams: [{
              field: "associatedIds"
              value: $associatedIds
              queryType: MATCH
            }]
            filterType: MUST
          }]
        }
      }
    ) {
      count,
      entities {
        associatedIds,
        id
      }
    },
    images: searchImageMetadata(
      input: {
        search: {
          pageInfo: {
            from: 0
            size: 100
          },
          queryFilters: [{
            queryParams: [{
              field: "associatedIds"
              value: $associatedIds
              queryType: MATCH
            }]
            filterType: MUST
          }]
        }
      }
    ) {
      count,
      entities {
        associatedIds,
        id,
      }
    }
  }
  `;

export const attachToPhaseQuery = `mutation AttachToPhase(
    $input: AttachToPhaseRequestInput!
) {
    attachToPhase(input: $input) {
        phases {
            name
            type
            dueDate
            section
            locks
            status
            isMandatory
            canLockQuestions
            isLocked
            questionIdsToLock
            allowedAttachmentTypes
            attachments {
                id
                name
                type
                createdAt
                uploadedBy
                description
            }
            id
            featuresEnabled
            assignee {
                assigneeType
                assigneeGroupName
                id
            }
            stages {
                ...stageFields
            }
        }
        caseNumber
        createdAt
        creator
        customSearchAttributes {
            key
            value
        }
        id
        incidentDateTime
        incidentGroupId
        name
        recordable
        reportable
        riskAssessments {
            likeliness
            riskCriticality
            severity
            type
        }
        riskCategory {
            category
            group
            hazard
        }
        createdScope: scope {
            nodeIds {
                id
            }
        }
        severity {
            editReason
            level
        }
        scope: dynamicScope {
            nodeIds {
                id
                isHierarchyNode
                tenantHierarchyId
            }
        }
        potentialSeverity {
            editReason
            level
        }
        status
        type
        updatedAt
        version
        audience {
            audienceType
            id
        }
    }
}
${phaseStageFragment}
`;

export const detachFromPhaseQuery = `mutation DetachFromPhase(
    $input: DetachFromPhaseRequestInput!
) {
    detachFromPhase(input: $input) {
        phases {
            name
            type
            dueDate
            section
            locks
            status
            isMandatory
            canLockQuestions
            isLocked
            questionIdsToLock
            allowedAttachmentTypes
            attachments {
                id
                name
                type
                createdAt
                uploadedBy
                description
            }
            id
            featuresEnabled
            assignee {
                assigneeType
                assigneeGroupName
                id
            }
            stages {
                ...stageFields
            }
        }
        caseNumber
        createdAt
        creator
        customSearchAttributes {
            key
            value
        }
        id
        incidentDateTime
        incidentGroupId
        name
        recordable
        reportable
        riskAssessments {
            likeliness
            riskCriticality
            severity
            type
        }
        riskCategory {
            category
            group
            hazard
        }
        createdScope: scope {
            nodeIds {
                id
            }
        }
        severity {
            editReason
            level
        }
        scope: dynamicScope {
            nodeIds {
                id
                isHierarchyNode
                tenantHierarchyId
            }
        }
        potentialSeverity {
            editReason
            level
        }
        status
        type
        updatedAt
        version
        audience {
            audienceType
            id
        }
    }
}
${phaseStageFragment}
`;

export const bulkDetachFromPhaseQuery = `mutation BulkDetachFromPhase(
    $input: BulkDetachFromPhaseRequestInput!
) {
    bulkDetachFromPhase(input: $input) {
        failures
        incident {
            phases {
                name
                type
                dueDate
                section
                locks
                status
                isMandatory
                canLockQuestions
                isLocked
                questionIdsToLock
                allowedAttachmentTypes
                attachments {
                    id
                    name
                    type
                    createdAt
                    uploadedBy
                    description
                }
                id
                featuresEnabled
                assignee {
                    assigneeType
                    assigneeGroupName
                    id
                }
                stages {
                    ...stageFields
                }
            }
            caseNumber
            createdAt
            creator
            customSearchAttributes {
                key
                value
            }
            id
            incidentDateTime
            incidentGroupId
            name
            recordable
            reportable
            riskAssessments {
                likeliness
                riskCriticality
                severity
                type
            }
            riskCategory {
                category
                group
                hazard
            }
            createdScope: scope {
                nodeIds {
                    id
                }
            }
            severity {
                editReason
                level
            }
            scope: dynamicScope {
                nodeIds {
                    id
                    isHierarchyNode
                    tenantHierarchyId
                }
            }
            potentialSeverity {
                editReason
                level
            }
            status
            type
            updatedAt
            version
            audience {
                audienceType
                id
            }
        }
    }
}
${phaseStageFragment}
`;
