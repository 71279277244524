import { get, identity as defaultMap } from 'lodash';
import { parseISO } from 'date-fns';
import { getDisplayTime, getFileDisplayName } from '@amzn/austin-core';

import { FORM_STATUS_LANG_ID } from '../../constants';
import { FIELD_TYPES } from '../../../components/fields/fieldTypes';

/**
 * Parses the value for Date/Time display
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapDateValue(value, question, context) {
    const { intl } = context;
    const { valueKey: date } = value;
    const isDateString = Boolean(date && typeof date === 'string');
    const parsedDate = isDateString ? parseISO(date) : date;

    return {
        ...value,
        valueKey: intl.formatDate(parsedDate, {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        })
    };
}

/**
 * Parses saved value from form field.  Returns productName / productId label.
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapProductValue(value) {
    let valueLabel = value?.valueKey;

    try {
        const { productName, productId } = JSON.parse(valueLabel);
        valueLabel = `${productName} / ${productId}`;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error parsing product field value', value);
    }

    return {
        ...value,
        valueKey: valueLabel
    };
}

/**
 * Checks context for map of file attachment ids.  Returns file display name for images and files.
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapFileAttachments(value, question, context) {
    const { intl, questionFileMap } = context;
    let valueLabel = value?.valueKey;
    const filesForQuestion = get(questionFileMap, question.id);

    if (filesForQuestion) {
        const files = filesForQuestion
            .filter((file) => file.suppliedName)
            .map((file) =>
                getFileDisplayName(
                    {
                        suppliedName: file.suppliedName,
                        fileSizeBytes: file.fileSizeBytes
                    },
                    intl
                )
            );

        const images = filesForQuestion
            .filter((file) => file.type === 'img')
            .map((file) => {
                const { origin, pathname } = new URL(file?.mediaThumbnailUri);
                return `${origin}${pathname}`;
            });

        valueLabel = files.concat(images).join(', ');
    }

    return {
        ...value,
        valueKey: valueLabel
    };
}

/**
 * Checks question answer template for defined answerLabels.
 * returns the label for the given value.
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapSelectValue(value, question) {
    const { valueKey } = value;
    const answerLabels = get(question, 'answerLabels', []);
    const valueLabel =
        answerLabels.find(({ key }) => key === valueKey)?.value ?? valueKey;

    return {
        ...value,
        valueKey: valueLabel
    };
}

/**
 * Gets localized string for given status ENUM
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapStatusValue(data, context = {}) {
    const { intl } = context;

    const formatValue = (value) => ({
        ...value,
        valueKey: intl.formatMessage({
            id: FORM_STATUS_LANG_ID[value.valueKey] ?? value.valueKey,
            defaultMessage: value.valueKey
        })
    });

    return {
        ...data,
        fieldKey: intl.formatMessage({
            id: 'ehs_incidents_label_status'
        }),
        previousValues: data.previousValues.map(formatValue),
        updatedValues: data.updatedValues.map(formatValue)
    };
}

/**
 * Parses the value for Date/Time display
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapTimeValue(value, question, context) {
    const { intl } = context;

    return {
        ...value,
        valueKey: getDisplayTime(value?.valueKey, intl, '', true)
    };
}

/**
 * Checks resolved context for matching alias.
 *
 * @param {Object} value
 * Change data value object
 *
 * @param {String} value.valueKey
 * Saved value of the change to be formatted
 *
 * @param {String | null} value.valueType
 * The type of value
 *
 * @param {Object} question
 * Question data for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 * @returns {Object}
 * Mapped change object.
 */
export function mapPersonIdValue(value, question, context) {
    const { valueKey: userId } = value;
    const label = get(context, `personData[${userId}].amazonAlias`, userId);

    return {
        ...value,
        valueKey: label
    };
}

/**
 * Gets the correct field mapping fn for question.answerTemplate.type,
 * Additional handlers for specific answerTemplateTypes can be defined here
 */
export const FORMATTERS_BY_QUESTION_TYPE = {
    [FIELD_TYPES.ATTACHMENT]: mapFileAttachments,
    [FIELD_TYPES.BUTTON_GROUP]: mapSelectValue,
    [FIELD_TYPES.DATE]: mapDateValue,
    [FIELD_TYPES.PERSON]: mapPersonIdValue,
    [FIELD_TYPES.PRODUCT_FIELD]: mapProductValue,
    [FIELD_TYPES.RADIO]: mapSelectValue,
    [FIELD_TYPES.SELECT]: mapSelectValue,
    [FIELD_TYPES.TIME]: mapTimeValue
};

/**
 * Gets the correct mapping handler for the question type.
 *
 * @param {Object} question
 * The question data from the form.
 *
 * @param {Object} context
 * Data object with relevant fields to Entity history changes
 *
 * @returns {Function} mapQuestionValues
 * Higher order function which will be called from changes.map with change value.
 *
 */
export function getQuestionMappingFn(question, context) {
    const renderAsType = get(
        question.answerTemplate,
        'renderAs',
        question.answerTemplate?.type
    );

    const mapFn = get(FORMATTERS_BY_QUESTION_TYPE, renderAsType, defaultMap);

    return function mapQuestionValues(value) {
        return mapFn(value, question, context);
    };
}

/**
 * Helper to determine if change field is a question provided in context
 * @param {String} fieldKey
 * The name of the field for the change.
 *
 * @param {String} entityType
 * The type of entity the change applies to
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 *
 * @returns {Boolean}
 *
 */
export function isFormQuestion(fieldKey, entityType, context = {}) {
    return Boolean(get(context?.questionMap, fieldKey, false));
}

/**
 * Wrapping fn to map the change data.  Will pass change values to specific mapping fn for the question type.
 * @param {Object} data
 * @param {String} data.fieldKey
 * The name of the field for the change.
 *
 * @param {Object} context
 * Passed data for the given entity type. Used to resolve the change display value.
 *
 * @returns {Object}
 * The mapped change object for display.
 *
 */
export function mapQuestionChanges(data, context = {}) {
    const question = get(context.questionMap, data.fieldKey);
    const { previousValues, updatedValues } = data;
    const mapQuestionValues = getQuestionMappingFn(question, context);

    return {
        ...data,
        previousValues: previousValues.map(mapQuestionValues),
        updatedValues: updatedValues.map(mapQuestionValues),
        fieldKey: question?.text
    };
}
