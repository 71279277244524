/* istanbul ignore file */
import { FIELD_TYPES } from '../../components/fields/fieldTypes';

const { DATE, TEXT_FIELD, RADIO } = FIELD_TYPES;

const section1 = {
    id: 'personInfo',
    name: 'Personal info',
    questions: [
        {
            id: 'firstName',
            name: 'firstName',
            externalId: 'firstName',
            text: 'First name',
            optional: false,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'lastName',
            name: 'lastName',
            externalId: 'lastName',
            text: 'Last name',
            optional: false,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'email',
            name: 'email',
            externalId: 'email',
            text: 'Email',
            optional: false,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'addressStreet',
            name: 'addressStreet',
            externalId: 'addressStreet',
            text: 'Street address',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'addressCity',
            name: 'addressCity',
            externalId: 'addressCity',
            text: 'City',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'addressState',
            name: 'addressState',
            externalId: 'addressState',
            text: 'State',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'addressZip',
            name: 'addressZip',
            externalId: 'addressZip',
            text: 'Zip',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'dateOfBirth',
            name: 'dateOfBirth',
            externalId: 'dateOfBirth',
            text: 'Date of birth',
            optional: true,
            answerTemplate: {
                type: DATE
            }
        },
        {
            id: 'gender',
            name: 'gender',
            externalId: 'gender',
            text: 'Gender',
            optional: true,
            answerTemplate: {
                type: RADIO,
                answerOptions: [
                    {
                        answerId: 'FEMALE',
                        text: 'Female'
                    },
                    {
                        answerId: 'MALE',
                        text: 'Male'
                    }
                ]
            }
        }
    ]
};

const section2 = {
    id: 'employmentInfo',
    name: 'Employment info',
    questions: [
        {
            id: 'dateHired',
            name: 'dateHired',
            externalId: 'dateHired',
            text: 'Date hired',
            optional: true,
            answerTemplate: {
                type: DATE
            }
        },
        {
            id: 'jobTitle',
            name: 'jobTitle',
            externalId: 'jobTitle',
            text: 'Job title',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'workerType',
            name: 'workerType',
            externalId: 'workerType',
            text: 'Worker type',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'agencyName',
            name: 'agencyName',
            externalId: 'agencyName',
            text: 'Agency name',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'amazonAlias',
            name: 'amazonAlias',
            externalId: 'amazonAlias',
            text: 'Amazon alias',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'employeeId',
            name: 'employeeId',
            externalId: 'employeeId',
            text: 'Employee ID',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'workLocation',
            name: 'workLocation',
            externalId: 'workLocation',
            text: 'Work location',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'managerPersonId',
            name: 'managerPersonId',
            externalId: 'managerPersonId',
            text: 'Manager person ID',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'managerFirstName',
            name: 'managerFirstName',
            externalId: 'managerFirstName',
            text: 'Manager first name',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'managerLastName',
            name: 'managerLastName',
            externalId: 'managerLastName',
            text: 'Manager last name',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        }
    ]
};

const OTRSection1 = {
    id: 'personInfo',
    name: 'Personal info',
    questions: [
        {
            id: 'firstName',
            name: 'firstName',
            externalId: 'firstName',
            text: 'First name',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        },
        {
            id: 'lastName',
            name: 'lastName',
            externalId: 'lastName',
            text: 'Last name',
            optional: true,
            answerTemplate: {
                type: TEXT_FIELD
            }
        }
    ]
};

export const createNonAmazonian = [section1, section2];
export const createOTRNonAmazonian = [OTRSection1];
