import { ISSUE_CRITICALITY_KEY_MAP, RISK_CRITICALITY, RISK_LIKELINESS, RISK_SEVERITY } from '@amzn/austin-module-actions';
import { ISSUE_LIKELINESS_KEY_MAP, TERM } from '../constants';
export var ISSUE_LIKELINESS_WITH_RATING = function (intl) { return [
    {
        label: intl.formatMessage({
            id: ISSUE_LIKELINESS_KEY_MAP[RISK_LIKELINESS.HIGHLY_UNLIKELY]
        }),
        rating: 1,
        tooltip: intl.formatMessage({
            id: 'ehs_common_label_riskAssessment_likeliness1'
        }),
        value: RISK_LIKELINESS.HIGHLY_UNLIKELY
    },
    {
        label: intl.formatMessage({
            id: ISSUE_LIKELINESS_KEY_MAP[RISK_LIKELINESS.UNLIKELY]
        }),
        rating: 2,
        tooltip: intl.formatMessage({
            id: 'ehs_common_label_riskAssessment_likeliness2'
        }),
        value: RISK_LIKELINESS.UNLIKELY
    },
    {
        label: intl.formatMessage({
            id: ISSUE_LIKELINESS_KEY_MAP[RISK_LIKELINESS.POSSIBLE]
        }),
        rating: 3,
        tooltip: intl.formatMessage({
            id: 'ehs_common_label_riskAssessment_likeliness3'
        }),
        value: RISK_LIKELINESS.POSSIBLE
    },
    {
        label: intl.formatMessage({
            id: ISSUE_LIKELINESS_KEY_MAP[RISK_LIKELINESS.LIKELY]
        }),
        rating: 4,
        tooltip: intl.formatMessage({
            id: 'ehs_common_label_riskAssessment_likeliness4'
        }),
        value: RISK_LIKELINESS.LIKELY
    },
    {
        label: intl.formatMessage({
            id: ISSUE_LIKELINESS_KEY_MAP[RISK_LIKELINESS.VERY_LIKELY]
        }),
        rating: 5,
        tooltip: intl.formatMessage({
            id: 'ehs_common_label_riskAssessment_likeliness5'
        }),
        value: RISK_LIKELINESS.VERY_LIKELY
    }
]; };
export var ISSUE_LIKELINESS_RATING = [
    {
        rating: 0,
        value: RISK_LIKELINESS.UNDETERMINED
    },
    {
        rating: 1,
        value: RISK_LIKELINESS.HIGHLY_UNLIKELY
    },
    {
        rating: 2,
        value: RISK_LIKELINESS.UNLIKELY
    },
    {
        rating: 3,
        value: RISK_LIKELINESS.POSSIBLE
    },
    {
        rating: 4,
        value: RISK_LIKELINESS.LIKELY
    },
    {
        rating: 5,
        value: RISK_LIKELINESS.VERY_LIKELY
    }
];
export var ISSUE_SEVERITIES_RATING = [
    {
        rating: 0,
        value: RISK_SEVERITY.UNDETERMINED
    },
    {
        rating: 1,
        value: RISK_SEVERITY.NEGLIGIBLE
    },
    {
        rating: 2,
        value: RISK_SEVERITY.MINOR
    },
    {
        rating: 3,
        value: RISK_SEVERITY.SIGNIFICANT
    },
    {
        rating: 4,
        value: RISK_SEVERITY.MAJOR
    },
    {
        rating: 5,
        value: RISK_SEVERITY.EXTENSIVE
    }
];
/**
 * Risk assessment configuration that will contain the criticality label,
 * color, slas all in one place.
 *
 * @param {object} [intl={}]
 * @returns {Map}
 */
export var RISK_ASSESSMENT_CONFIG = function (
// @ts-ignore
intl) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (intl === void 0) { intl = { formatMessage: function () { } }; }
    return new Map([
        [
            RISK_CRITICALITY.UNDETERMINED,
            {
                maxRange: 0,
                minRange: 0,
                sla: (_a = {},
                    _a[TERM.IMMEDIATE] = {
                        max: 1,
                        standard: 1
                    },
                    _a[TERM.LONG] = {
                        max: 1,
                        standard: 1
                    },
                    _a),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.UNDETERMINED]
                }),
                value: RISK_CRITICALITY.UNDETERMINED
            }
        ],
        [
            RISK_CRITICALITY.NEGLIGIBLE,
            {
                maxRange: 2,
                minRange: 1,
                sla: (_b = {},
                    _b[TERM.IMMEDIATE] = {
                        max: 2,
                        standard: 1
                    },
                    _b[TERM.LONG] = {
                        max: 70,
                        standard: 60
                    },
                    _b),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.NEGLIGIBLE]
                }),
                value: RISK_CRITICALITY.NEGLIGIBLE
            }
        ],
        [
            RISK_CRITICALITY.LOW,
            {
                maxRange: 4,
                minRange: 3,
                sla: (_c = {},
                    _c[TERM.IMMEDIATE] = {
                        max: 2,
                        standard: 1
                    },
                    _c[TERM.LONG] = {
                        max: 70,
                        standard: 60
                    },
                    _c),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.LOW]
                }),
                value: RISK_CRITICALITY.LOW
            }
        ],
        [
            RISK_CRITICALITY.MEDIUM,
            {
                maxRange: 6,
                minRange: 5,
                sla: (_d = {},
                    _d[TERM.IMMEDIATE] = {
                        max: 2,
                        standard: 1
                    },
                    _d[TERM.LONG] = {
                        max: 70,
                        standard: 60
                    },
                    _d),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.MEDIUM]
                }),
                value: RISK_CRITICALITY.MEDIUM
            }
        ],
        [
            RISK_CRITICALITY.SUBSTANTIAL,
            {
                maxRange: 10,
                minRange: 7,
                sla: (_e = {},
                    _e[TERM.IMMEDIATE] = {
                        max: 1,
                        standard: 1
                    },
                    _e[TERM.LONG] = {
                        max: 20,
                        standard: 15
                    },
                    _e),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.SUBSTANTIAL]
                }),
                value: RISK_CRITICALITY.SUBSTANTIAL
            }
        ],
        [
            RISK_CRITICALITY.HIGH,
            {
                maxRange: 16,
                minRange: 11,
                sla: (_f = {},
                    _f[TERM.IMMEDIATE] = {
                        max: 1,
                        standard: 1
                    },
                    _f[TERM.LONG] = {
                        max: 20,
                        standard: 15
                    },
                    _f),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.HIGH]
                }),
                value: RISK_CRITICALITY.HIGH
            }
        ],
        [
            RISK_CRITICALITY.CRITICAL,
            {
                maxRange: 25,
                minRange: 17,
                sla: (_g = {},
                    _g[TERM.IMMEDIATE] = {
                        max: 1,
                        standard: 1
                    },
                    _g[TERM.LONG] = {
                        max: 20,
                        standard: 15
                    },
                    _g),
                text: intl.formatMessage({
                    id: ISSUE_CRITICALITY_KEY_MAP[RISK_CRITICALITY.CRITICAL]
                }),
                value: RISK_CRITICALITY.CRITICAL
            }
        ]
    ]);
};
export function getOverallCriticality(issueRiskAssessments) {
    var resultCriticality = 0;
    if (issueRiskAssessments && issueRiskAssessments.length > 0) {
        resultCriticality =
            issueRiskAssessments[issueRiskAssessments.length - 1]
                .criticalityCalculation;
    }
    return resultCriticality;
}
